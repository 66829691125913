import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Table} from "../../../../components/table/table";
import {Animate, Button, Card} from "../../../../components/lib";


export default function BrandCampaigns() {
    const {id} = useParams()

    const brandQ = useQuery({
        queryKey: ['10dlc', 'brands', id],
        queryFn: () => axios.get(`/api/10dlc/brands/${id}`).then(res => res.data)
    })

    const brand = brandQ.data?.brand || {}

    const {data, isLoading} = useQuery({
        queryKey: ['10dlc', 'brands', id, 'campaigns'],
        queryFn: () => axios.get(`/api/10dlc/brands/${id}/campaigns`).then(res => res.data),
        refetchOnWindowFocus: true
    })

    return (
        <Animate>
            <Card loading={brandQ.isLoading} title={'Brand Details'}>
                <div className={'grid gap-3'}>
                    {
                        Object.keys(brand).map(key => (
                            <div className={'flex gap-2'}>
                                <div className={'font-medium min-w-[120px] max-w-[300px] w-full'}>{key}:</div>
                                <div>{brand[key]}</div>
                            </div>
                        ))
                    }
                </div>
            </Card>

            <div className="mb-4">
                <Button
                    text='New Campaign'
                    className='ml-auto'
                    color='blue'
                    goto={`/10dlc/brands/${id}/campaigns/new`}
                    small
                />
            </div>

            <Card>
                <Table
                    loading={isLoading}
                    data={data?.campaigns}
                    show={['Campaign_ID', 'Description', 'Submitted', 'Status', 'AT&T_Status', 'TMO_Status']}
                    badge={{
                        col: 'Status', color: 'gray', condition: [
                            {value: 'ACTIVE', color: 'green'}
                        ]
                    }}
                />
            </Card>
        </Animate>
    )
}