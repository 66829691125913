/***
*
*   error/LIST
*   List the application errors (paginated)
*
**********/

import { Fragment, useState, useEffect } from 'react';
import { Card, Table, Search, Paginate, useAPI, useView } from 'components/lib';

export default function Errors(props) {
    const view = useView()

    // state 
    const [search, setSearch] = useState('');
    const [offset, setOffset] = useState(0);

    // show 25 results at a time
    const limit = 25;

    const { data, loading, refetch } = useAPI(`/api/error?search=${search}&offset=${offset}&limit=${limit}`);


    function deleteError(data, callback) {
        view.modal.show({
            title: 'Delete error',
            form: {},
            buttonText: 'Delete error',
            text: 'Are you sure you want to delete this error?',
            url: `/api/error/${data.id}`,
            method: 'DELETE',
            destructive: true,

        }, () => {
            view.notification.show('error deleted', 'success', true);
            callback();

        });
    }

    function solveError(data) {
        if (data.solved) return

        view.modal.show({
            title: 'Mark as solved',
            form: {},
            buttonText: 'Yes mark as solved',
            text: 'Are you sure you want to mark this error as solved?',
            url: `/api/error/${data.id}/solve`,
            method: 'PUT',
            destructive: true,

        }, () => {
            view.notification.show('error marked as solved', 'success', true);
            refetch()
        });
    }


    return (
        <Fragment>

            <Search throttle={1000} callback={x => setSearch(x)} /><br />

            <Paginate
                offset={offset}
                limit={limit}
                total={data?.total}
                loading={loading}
                onChange={x => setOffset(x)}
            />

            <Card>
                <Table
                    loading={loading}
                    data={data?.results}
                    show={['message', 'status', 'time', 'email', 'method', 'endpoint']}
                    badge={{
                        col: 'status', color: 'red', condition: [
                            { value: 'solved', color: 'green' },
                            { value: 'unsolved', color: 'red' },
                        ]
                    }}
                    actions={{
                        delete: deleteError,
                        view: { url: '/errors', col: 'id' },
                        custom: [{ icon: 'check', action: solveError }]
                    }}
                />
            </Card>
        </Fragment>
    )
}