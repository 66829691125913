import React, { useEffect, useState } from 'react'
import { Animate, Button, Card, Checkbox, Fieldset, ModalContainer, NumberInput, Radio, Select, Table, TextInput, useView } from 'components/lib'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'

function Coupons() {
    const viewContext = useView()

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['coupons'],
        queryFn: () => axios.get('/api/stripe/coupons'),
    })

    useEffect(() => {
        if (error) {
            viewContext.handleError(error)
        }

    }, [error])

    const durations = [{ label: 'Once', value: 'once' }, { label: 'Repeating', value: 'repeating' }, { label: 'Forever', value: 'forever' }]

    const addMutation = useMutation(
        ['addCoupon'],
        (values) => axios.post('/api/stripe/addcoupon', values),
    )

    function deleteCoupon(data, callback) {
        viewContext.modal.show({
            title: 'Delete Coupon',
            form: {},
            buttonText: 'Delete Coupon',
            text: `Are you sure you want to delete ${data.name}?`,
            url: `/api/stripe/coupons/${data.name}`,
            method: 'DELETE',
            destructive: true,
        }, () => {
            viewContext.notification.show(`Coupon ${data.name} was deleted`, 'success', true);
            callback()
        });
    }

    const [modal, setModal] = useState(false)
    const initalForm = {
        apply_for: 'Both',
        campaigns: 1,
        name: '',
        apply_to: data?.data?.data?.plans,
        setup_fee_off: 0,
        off: 1,
        vm_off: 0,
        cb_off: 0,
        type: 'amount',
        setup_fee_type: 'amount',
        vm_type: 'amount',
        cb_type: 'amount',
        numberOfMonths: 1,
        numberOfCoupons: 10,
        call_to_text_enabled: false,
        setup_fee_enabled: false,
        vm_enabled: false,
        cb_enabled: false,
    }

    const [form, setForm] = useState(initalForm)

    const handleFieldChange = (name, value, valid) => {
        setForm(prev => ({ ...prev, [name]: value }))

        if (!valid && !value && typeof value !== 'boolean') {
            setValids(prev => [...prev, name])
        } else {
            setValids(prev => prev.filter(p => p !== name))
        }
    }

    const defaultReqs = ['coupon', 'type', 'numberOfMonths', 'duration', 'numberOfCoupons', 'campaigns']
    const [required, setRequired] = useState(defaultReqs)

    const [valids, setValids] = useState([])
    const getValid = (name) => {
        return valids.length && !valids.includes(name)
    }

    const handleAddCoupon = async () => {
        if (valids.length) return
        for (let i = 0; i < required.length; i++) {
            if (!form[required[i]]) {
                setValids(prev => [...prev, required[i]])
                return
            }
        }

        try {
            await addMutation.mutateAsync(form)
            refetch()
            setForm(initalForm)
            setModal(false)

        } catch (e) {
            viewContext.handleError(e)
        }
    }

    return (
        <Animate>
            <div className='bg-white p-2 min-h-screen'>
                <div className="flex justify-end mb-4">
                    <Button
                        text={modal ? 'Cancel' : 'Add Coupon'}
                        color={modal ? 'red' : 'blue'}
                        small
                        action={() => setModal(prev => !prev)}
                    />
                </div>

                {
                    modal && data?.data?.data && (
                        <div className='bg-gray-100  mb-4 shadow-md border p-3 rounded'>
                            <div className={`grid gap-3 md:grid-cols-3 ${form.duration === 'repeating' ? 'xl:grid-cols-5' : 'xl:grid-cols-4'}`}>
                                <TextInput
                                    label='Name'
                                    name='coupon'
                                    onChange={handleFieldChange}
                                    value={form.coupon}
                                    required
                                    valid={getValid('coupon')}
                                />

                                <NumberInput
                                    label='Campaigns'
                                    name='campaigns'
                                    onChange={handleFieldChange}
                                    value={form.campaigns}
                                    required
                                    valid={getValid('campaigns')}
                                />

                                <Select
                                    label='Duration'
                                    name='duration'
                                    onChange={handleFieldChange}
                                    options={durations}
                                    value={form.duration}
                                    required
                                    valid={getValid('duration')}
                                />


                                {
                                    form.duration === 'repeating' && (
                                        <NumberInput
                                            label='Number Of Months'
                                            name='numberOfMonths'
                                            onChange={handleFieldChange}
                                            value={form.numberOfMonths}
                                            required
                                            valid={getValid('numberOfMonths')}
                                        />
                                    )
                                }


                                <NumberInput
                                    label='Number Of Coupons'
                                    name='numberOfCoupons'
                                    onChange={handleFieldChange}
                                    value={form.numberOfCoupons}
                                    required
                                    valid={getValid('numberOfCoupons')}
                                />

                                <Button
                                    action={handleAddCoupon}
                                    disabled={valids.length || addMutation.isLoading}
                                    loading={addMutation.isLoading}
                                    small
                                />
                            </div>

                            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
                                <div>
                                    <div className="mb-4">
                                        <Checkbox
                                            name='call_to_text_enabled'
                                            option='Call to Text, VM and CB'
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    {
                                        form.call_to_text_enabled && (
                                            <>
                                                <Select
                                                    label='Type'
                                                    name='type'
                                                    onChange={handleFieldChange}
                                                    options={[{ label: 'Amount', value: 'amount' }, { label: 'Percent', value: 'percent' }]}
                                                    default={form.type}
                                                    required
                                                    valid={getValid('type')}
                                                />
                                                <NumberInput
                                                    label={form.type}
                                                    name='off'
                                                    onChange={handleFieldChange}
                                                    value={form.off}
                                                    required
                                                    min={0.01}
                                                    valid={getValid('off')}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                <div>
                                    <div className="mb-4">
                                        <Checkbox
                                            name='setup_fee_enabled'
                                            option='Setup fee Enabled'
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    {
                                        form.setup_fee_enabled && (
                                            <>
                                                <Select
                                                    label='Type'
                                                    name='setup_fee_type'
                                                    onChange={handleFieldChange}
                                                    options={[{ label: 'Amount', value: 'amount' }, { label: 'Percent', value: 'percent' }]}
                                                    default={form.setup_fee_type}
                                                    required
                                                    valid={getValid('setup_fee_type')}
                                                />
                                                <NumberInput
                                                    label={form.setup_fee_type}
                                                    name='setup_fee_off'
                                                    onChange={handleFieldChange}
                                                    value={form.setup_fee_off}
                                                    required
                                                    min={0.01}
                                                    valid={getValid('setup_fee_off')}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                {/* <div>
                                    <div className="mb-4">
                                        <Checkbox
                                            name='vm_enabled'
                                            option='Voicemail Enabled'
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    {
                                        form.vm_enabled && (
                                            <>
                                                <Select
                                                    label='Type'
                                                    name='vm_type'
                                                    onChange={handleFieldChange}
                                                    options={[{ label: 'Amount', value: 'amount' }, { label: 'Percent', value: 'percent' }]}
                                                    default={form.vm_type}
                                                    required
                                                    valid={getValid('vm_type')}
                                                />
                                                <NumberInput
                                                    label={form.vm_type}
                                                    name='vm_off'
                                                    onChange={handleFieldChange}
                                                    value={form.vm_off}
                                                    required
                                                    min={0.01}
                                                    valid={getValid('vm_off')}
                                                />
                                            </>
                                        )
                                    }
                                </div> */}
                                {/* <div>
                                    <div className="mb-4">
                                        <Checkbox
                                            name='cb_enabled'
                                            option='Callback Enabled'
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    {
                                        form.cb_enabled && (
                                            <>
                                                <Select
                                                    label='Type'
                                                    name='cb_type'
                                                    onChange={handleFieldChange}
                                                    options={[{ label: 'Amount', value: 'amount' }, { label: 'Percent', value: 'percent' }]}
                                                    default={form.cb_type}
                                                    required
                                                    valid={getValid('cb_type')}
                                                />
                                                <NumberInput
                                                    label={form.cb_type}
                                                    name='cb_off'
                                                    onChange={handleFieldChange}
                                                    value={form.cb_off}
                                                    required
                                                    min={0.01}
                                                    valid={getValid('cb_off')}
                                                />
                                            </>
                                        )
                                    }
                                </div> */}
                            </div>

                            <Button
                                action={handleAddCoupon}
                                small
                                text={'Save'}
                                className='mt-6'
                            />
                        </div>

                    )
                }

                <Table
                    search
                    data={data?.data?.data?.coupons}
                    badge={{
                        col: 'valid', color: 'green', condition: [
                            { value: true, color: 'green' },
                            { value: false, color: 'red' }
                        ]
                    }}
                    loading={isLoading}
                    actions={{
                        delete: deleteCoupon
                    }}
                />
            </div>
        </Animate>
    )
}

export default Coupons