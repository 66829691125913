import { Animate, Card, Form, useView } from 'components/lib'
import React from 'react'
import Nav from './Nav'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

function Gpt() {
    const view = useView()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['gpt_settings'],
        queryFn: () => axios.get("/api/settings/gpt_settings")
    })

    return (
        <>
            <Nav />
            <Animate>
                <Card className='grid gap-6 lg:grid-cols-2' loading={isLoading}>
                    <div>
                        <h1 className='font-semibold text-lg mb-2'>Spam Filter</h1>
                        <Form
                            title='Missed call sms Settings'
                            inputs={{
                                gpt_model: {
                                    label: 'Model',
                                    type: 'text',
                                    required: true,
                                    value: data?.data?.gpt_model
                                },
                                gpt_temperature: {
                                    label: 'temperature',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gpt_temperature
                                },
                                gpt_max_tokens: {
                                    label: 'Max Tokens',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gpt_max_tokens
                                },
                                gpt_top_p: {
                                    label: 'Top P',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gpt_top_p
                                },
                                gpt_frequency_penalty: {
                                    label: 'Frequency Penalty',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gpt_frequency_penalty
                                },
                                gpt_presence_penalty: {
                                    label: 'Presence Penalty',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gpt_presence_penalty
                                },
                                gpt_spam_filter: {
                                    label: 'Prompt',
                                    type: 'textarea',
                                    rows: 15,
                                    required: true,
                                    value: data?.data?.gpt_spam_filter
                                }
                            }}
                            buttonText='Save'
                            url='/api/settings/gpt_settings'
                            method='PUT'
                            callback={(res) => {
                                view.notification.show(res.data?.data?.message, 'success', true)
                                refetch()
                            }}
                        />
                    </div>
                    <div>
                        <h1 className='font-semibold text-lg mb-2'>Voicemail Tagging</h1>
                        <Form
                            title='Missed call sms Settings'
                            inputs={{
                                vm_gpt_model: {
                                    label: 'Model',
                                    type: 'text',
                                    required: true,
                                    value: data?.data?.vm_gpt_model
                                },
                                vm_gpt_temperature: {
                                    label: 'temperature',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.vm_gpt_temperature
                                },
                                vm_gpt_max_tokens: {
                                    label: 'Max Tokens',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.vm_gpt_max_tokens
                                },
                                vm_gpt_top_p: {
                                    label: 'Top P',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.vm_gpt_top_p
                                },
                                vm_gpt_frequency_penalty: {
                                    label: 'Frequency Penalty',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.vm_gpt_frequency_penalty
                                },
                                vm_gpt_presence_penalty: {
                                    label: 'Presence Penalty',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.vm_gpt_presence_penalty
                                },
                                vm_gpt_prompt: {
                                    label: 'Prompt',
                                    type: 'textarea',
                                    rows: 5,
                                    required: true,
                                    value: data?.data?.vm_gpt_prompt
                                }
                            }}
                            buttonText='Save'
                            url='/api/settings/gpt_settings'
                            method='PUT'
                            callback={(res) => {
                                view.notification.show(res.data?.data?.message, 'success', true)
                                refetch()
                            }}
                        />
                    </div>
                </Card>
            </Animate>
        </>
    )
}

export default Gpt