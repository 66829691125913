import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Card, useView, Table, SubNav, Button, ModalContainer, TextInput, Paginate, Search } from 'components/lib'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CampaignStats from './CampaignStats'
import UserInfo from './UserInfo'

function UserCampaigns() {
  const { id } = useParams()

  const [notesSearch, setNotesSearch] = useState('');
  const [notesOffset, setNotesOffset] = useState(0);
  const notesLimit = 25

  const notes = useQuery(
    ['notes', id, notesSearch, notesOffset, notesLimit],
    () => axios.get(`/api/user/${id}/notes?search=${notesSearch}&offset=${notesOffset}&limit=${notesLimit}`).then(res => res.data?.data),
  )

  const { data, isLoading, refetch } = useQuery(
    ['campaigns', id],
    () => axios.get(`/api/campaigns/user/${id}`)
  )

  const campaigns = data?.data?.campaigns || []

  const view = useView()
  const navigate = useNavigate()


  const editCampaign = () => {

  }

  const deleteCampaign = (c) => {
    view.modal.show({
      title: 'Delete Campaign',
      form: {},
      buttonText: 'Yes, delete',
      text: `Are you sure you want to delete "${c.campaign_name}" campaign?`,
      url: `/api/campaigns/${c.id}`,
      method: 'DELETE',
      destructive: true,
    }, () => {
      view.notification.show(`Campaign ${c.campaign_name} was deleted`, 'success', true);
      refetch()
    });
  }

  const [activeTab, setActiveTab] = useState('all_campaigns')

  const tabs = [
    { action: () => setActiveTab('all_campaigns'), label: 'all_campaigns' },
    { action: () => setActiveTab('notes'), label: 'notes' },
  ]

  const [modal, setModal] = useState({ id: 0, open: false, type: '', text: '' })

  const handleNoteText = (_, value) => {
    setModal(prev => ({ ...prev, text: value }))
  }

  const leaveNoteMutation = useMutation(
    ['leave_note'],
    () => axios.post(`/api/user/${id}/notes`, { note: modal.text }),
    {
      onSuccess: (res) => {
        view.notification.show(res.data.message, 'success', true);
        notes.refetch()
        setModal({})
      },
      onError: (e) => view.handleError(e)
    }
  )

  const updateNoteMutation = useMutation(
    ['update_note', modal.id],
    () => axios.put(`/api/user/${id}/notes/${modal.id}`, { note: modal.text }),
    {
      onSuccess: (res) => {
        view.notification.show(res.data.message, 'success', true);
        notes.refetch()
        setModal({})
      },
      onError: (e) => view.handleError(e)
    }
  )

  const handleModalSubmit = () => {
    if (modal.type === 'Add') leaveNoteMutation.mutate()
    if (modal.type === 'Update') updateNoteMutation.mutate()
  }


  return (
    <Animate loading={isLoading}>

      <UserInfo
        id={id}
      />

      <CampaignStats
        campaign={campaigns.map(c => c.id)}
      />

      <SubNav
        active={activeTab}
        items={tabs}
      />

      <Card>
        {
          activeTab === 'all_campaigns' && (
            <Table
              search
              data={campaigns}
              loading={isLoading}
              show={['campaign_name', 'business_address', 'FWD_number', 'plan', 'status', 'date_created']}
              badge={{
                col: 'status', color: 'green', condition: [
                  { value: 'active', color: 'green' },
                  { value: 'deleted', color: 'red' },
                  { value: 'past due', color: 'orange' },
                  { value: 'delinquent', color: 'purple' },
                  { value: 'suspended', color: 'yellow' },
                  { value: 'cancelled', color: 'gray' },
                ]
              }}
              actions={{
                custom: [{ icon: 'eye', action: (c) => navigate(`/campaigns/${c.id}?user=${c.user_id}`) }],
                edit: editCampaign,
                delete: deleteCampaign,
              }}
            />
          )
        }

        {
          activeTab === 'notes' && (
            <>
              {
                modal.open && (
                  <ModalContainer close={() => setModal({})} >
                    <Card title='Leave Note' className='flex flex-col'>
                      <p className='text-sm'>Leave a note about this client</p>
                      <TextInput
                        type='textarea'
                        rows={5}
                        onChange={handleNoteText}
                        value={modal.text}
                      />

                      <Button
                        text={modal.type}
                        small
                        action={handleModalSubmit}
                      />
                    </Card>
                  </ModalContainer>
                )
              }

              <Button
                text='Leave Note'
                small
                action={() => setModal({ open: true, type: 'Add', text: '' })}
                loading={leaveNoteMutation.isLoading || updateNoteMutation.isLoading}
              />

              <div className="my-6 flex flex-col gap-4">
                <Search throttle={1000} callback={x => setNotesSearch(x)} />
                <Paginate
                  offset={notesOffset}
                  limit={notesLimit}
                  total={notes?.data?.total}
                  loading={notes.isLoading}
                  onChange={x => setNotesOffset(x)}
                />
              </div>

              <Table
                data={notes.data}
                loading={notes.isLoading}
                show={['note', 'from_user', 'created_at']}
                actions={{
                  edit: (note) => setModal({ open: true, type: 'Update', id: note.id, text: note.note }),
                  delete: (note) => view.modal.show({
                    title: 'Delete Note',
                    form: {},
                    buttonText: 'Yes, delete',
                    text: `Are you sure you want to delete this note?`,
                    url: `/api/user/${id}/notes/${note.id}`,
                    method: 'DELETE',
                    destructive: true,
                  }, () => {
                    view.notification.show(`Note was deleted`, 'success', true);
                    notes.refetch()
                  }),
                }}
              />
            </>
          )
        }
      </Card>
    </Animate>
  )
}

export default UserCampaigns