import {Card, Form, useView} from "components/lib"
import {Link, useNavigate} from 'react-router-dom'
import {states} from "../../../helper/states";

export default function NewBrand() {
    const navigate = useNavigate()
    const view = useView()

    return (
        <Card>
            <div className={'mb-6'}>
                <Link to={'/10dlc/brands'} className={'text-blue-400 underline'}>Back</Link>
            </div>

            <Form
                inputs={{
                    CompanyName: {
                        label: 'Company Name',
                        type: 'text',
                        required: true,
                        containerClass: 'col-span-2'
                    },
                    Country: {
                        label: 'Country',
                        type: 'select',
                        required: true,
                        options: countries,
                        default: 'US'
                    },
                    Street: {
                        label: 'Business Street Address',
                        type: 'text',
                        required: true
                    },
                    City: {
                        label: 'City',
                        type: 'text',
                        required: true
                    },
                    State: {
                        label: 'State',
                        type: 'select',
                        required: true,
                        relative: 'Country',
                        options: states
                    },
                    PostalCode: {
                        label: 'Zip Code',
                        type: 'text',
                        required: true
                    },
                    Vertical: {
                        label: 'Vertical',
                        type: 'select',
                        required: true,
                        options: Verticals
                    },
                    Ein: {
                        label: 'EIN Number',
                        type: 'text',
                        required: true
                    },
                    Email: {
                        label: 'Email Address',
                        type: 'email'
                    },
                    Phone: {
                        label: 'Phone Number',
                        type: 'phone',
                        required: true
                    },
                    Website: {
                        label: 'Website',
                        type: 'text'
                    },

                }}
                inputsClassName={'grid grid-cols-2 gap-2'}
                buttonText='Create Brand'
                url='/api/10dlc/brands/new'
                method='POST'
                callback={(res) => {
                    if (res.data.brand) {
                        return navigate('/10dlc/brands')
                    }

                    return view.notification.show('Failed to create brand, please check our inputs.', 'error')
                }}
            />
        </Card>
    )
}

export const Verticals = [
    {label: "Real Estate", value: "REAL_ESTATE"},
    {label: "Healthcare", value: "HEALTHCARE"},
    {label: "Energy", value: "ENERGY"},
    {label: "Entertainment", value: "ENTERTAINMENT"},
    {label: "Retail", value: "RETAIL"},
    {label: "Agriculture", value: "AGRICULTURE"},
    {label: "Insurance", value: "INSURANCE"},
    {label: "Education", value: "EDUCATION"},
    {label: "Hospitality", value: "HOSPITALITY"},
    {label: "Financial", value: "FINANCIAL"},
    {label: "Gambling", value: "GAMBLING"},
    {label: "Construction", value: "CONSTRUCTION"},
    {label: "NGO", value: "NGO"},
    {label: "Manufacturing", value: "MANUFACTURING"},
    {label: "Government", value: "GOVERNMENT"},
    {label: "Technology", value: "TECHNOLOGY"},
    {label: "Communication", value: "COMMUNICATION"}
]

const countries = [
    {label: "Canada", value: "CA"},
    {label: "United States", value: "US"}
];