import { SubNav } from 'components/lib'
import React from 'react'

function Nav() {
    return (
        <SubNav items={[
            { link: '/settings/workinghourssms', label: 'Working Hours SMS settings' },
            { link: '/settings/afterhourssms', label: 'After hours SMS settings' },
            { link: '/settings/aws', label: 'AWS Settings' },
            { link: '/settings/gpt', label: 'GPT Settings' },
            { link: '/settings/plan_options', label: 'Plan Options' },
            { link: '/settings/core_sms_handler', label: 'Core SMS Handler' },
            { link: '/settings/supported_types', label: 'Supported Types' },
            { link: '/settings/vm_cb_settings', label: 'Voicemail & Callback' },
            { link: '/settings/users', label: 'Users' },
            { link: '/settings/10dlc', label: '10 DLC' },
        ]} />
    )
}

export default Nav