import { Animate, Button, Card, Table, TitleRow, useAPI, useView } from 'components/lib'
import React from 'react'

function Shortlinks() {
    const { data, loading, refetch } = useAPI('/api/shortlinks')
    const view = useView()

    function handleShortlink(data) {
        view.modal.show({
            title: data ? 'Update' : 'Create' + ' Short Link',
            form: {
                original_url: {
                    label: 'Original Url',
                    type: 'text',
                    required: true,
                    ...data && { value: data.original_url }
                },
                description: {
                    label: 'Description',
                    type: 'textarea',
                    required: true,
                    ...data && { value: data.description }
                }
            },
            buttonText:  data ? 'Update' : 'Create',
            url: data ? `/api/shortlinks/${data.id}` : '/api/shortlinks/shorten',
            method: data ? 'PUT' : 'POST',
        }, () => {
            view.notification.show('Short Link has been saved', 'success', true);
            refetch()
        });
    }

    function deleteShortlink(data, callback) {
        view.modal.show({
            title: 'Delete Shortlink',
            form: {},
            buttonText: 'Delete',
            text: `Are you sure you want to delete ${data.original_url}?`,
            url: `/api/shortlinks/${data.id}`,
            method: 'DELETE',
            destructive: true,
        }, () => {
            view.notification.show(`Shortlink ${data.original_url} was deleted`, 'success', true);
            callback()
        });
    }

    return (
        <Animate>
            <Card loading={loading}>
                <TitleRow title='Short Links' />
                <div className="flex justify-end mb-4">
                    <Button
                        text='Create'
                        small
                        color='blue'
                        action={handleShortlink}
                    />
                </div>
                <Table
                    data={data?.links}
                    show={['original_url', 'short_url', 'description', 'clicks', 'created_at']}
                    actions={{
                        delete: deleteShortlink,
                        view: { url: '/short_link', col: 'id' },
                        edit: handleShortlink
                    }}
                />
            </Card>
        </Animate>
    )
}

export default Shortlinks