import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { Banner, View } from 'components/lib';
import { NotFound } from 'views/404';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

// tailwind css
import '../css/style.css';
import { useState } from 'react';

const routes = [

  ...require('routes').default,

]

export default function App(props) {

  const user = JSON.parse(localStorage.getItem('user'));
  Axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL

  if (user?.token) {

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  }

  const [queryClient] = useState(new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
  }))
  // render the routes
  return (
    <>
      {
        process.env.REACT_APP_SERVER === 'DEV' && (
          <Banner
            text='DEV ADMIN SERVER'
            icon='tool'
            purple
          />
        )
      }

      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              {routes.map(route => {

                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={

                      route.permission ?
                        <PrivateRoute permission={route.permission}>
                          <View display={route.view} layout={route.layout} title={route.title} />
                        </PrivateRoute> :
                        <View display={route.view} layout={route.layout} title={route.title} />

                    }
                  />
                )
              })}

              { /* 404 */}
              <Route path='*' element={<View display={NotFound} layout='home' title='404 Not Found' />} />

            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}
