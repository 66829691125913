import { ClassHelper, PopOver } from 'components/lib';
import Style from './label.tailwind.js';

export function Label(props) {

  const labelStyle = ClassHelper(Style, props);

  return (
    <label className={labelStyle} htmlFor={props.for}>
      {props.text}
      {
        props.popOver && <PopOver text={props.popOver} />
      }
    </label>
  );
}