const Style = {

  table: 'w-full border-separate',
  search: 'mb-4',
  loading: 'relative py-8',
  badge: 'ml-0 whitespace-nowrap',
  empty: 'py-3',
  thead: 'font-semibold table-header-group',
  th: 'text-left outline-0 capitalize p-5 pt-0 border-b border-dotted border-slate-100 first:pl-0 last:pr-0 whitespace-nowrap',
  sort: `relative cursor-pointer after:absolute after:right-0 after:top-1/2 after:mt-1
    after:w-3 after:h-3 after:opacity-50 after:-translate-y-1/2 after:bg-contain`,

  asc: `after:bg-[url('components/table/icons/ico-sort-asc.svg')]`,
  desc: `after:bg-[url('components/table/icons/ico-sort-dsc.svg')]`,

  cell: `first:pl-0 last:pr-0 last:pb-5 last:border-b last:border-solid 
    last:border-slate-100 w-auto mb-0 p-3 border-b :border-solid border-slate-100`,

  actions: `whitespace-nowrap mb-0 border-b border-slate-100 border-solid`,

  actionButton: 'inline-block whitespace-nowrap -top-1 mr-3 bg-transparent last:mr-0',

}

export default Style;