import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './style.scss'


export function DateRange(props) {
    return (
        <DateRangePicker
            onChange={props.onChange}
            value={props.value}
            className='bg-white rounded-md border border-slate-300 mb-4'
            calendarClassName={['rounded-md border-slate-300']}
        />
    );
}