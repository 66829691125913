import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Button, Card, NumberInput, PopOver, ShortCodes, TextInput, useAPI, useView } from 'components/lib'
import React, { useEffect, useState } from 'react'
import Nav from './Nav'

function VM_CB() {
    const [settings, setSettings] = useState({})
    const view = useView()

    const { data, loading } = useAPI('/api/settings/vm_cb_setting')

    useEffect(() => {
        if (data) {
            setSettings(data)
        }
    }, [data])

    const handleChnage = (name, value) => {
        setSettings(prev => ({ ...prev, [name]: value }))
    }

    const saveMutation = useMutation(
        ['vm_cb_setting'],
        () => axios.put('/api/settings/vm_cb_setting', settings).then(res => res.data.data)
    )

    useEffect(() => {
        if (saveMutation.isSuccess) {
            view.notification.show(saveMutation.data.message, 'success', true)
        }
    }, [saveMutation.isSuccess])

    return (
        <>
            <Nav />

            <Animate loading={loading}>
                <Card loading={loading} title={`Voicemail & Callback Settings`}>

                    <div className="grid lg:grid-cols-2 gap-4">
                        <div>
                            <div className="grid sm:grid-cols-2 gap-x-4">
                                <NumberInput
                                    label='Max Emails'
                                    name='max_emails'
                                    value={settings.max_emails}
                                    onChange={handleChnage}
                                    required
                                />
                                <NumberInput
                                    label='Max Phone Numbers'
                                    name='max_phones'
                                    className='mb-4'
                                    value={settings.max_phones}
                                    onChange={handleChnage}
                                    required
                                />
                            </div>
                            <TextInput
                                label='Voicemail Text SMS'
                                name='vm_sms'
                                value={settings.vm_sms}
                                onChange={handleChnage}
                                required
                                type='textarea'
                                rows={10}
                            />

                            <TextInput
                                label='Callback Text SMS'
                                name='cb_sms'
                                value={settings.cb_sms}
                                onChange={handleChnage}
                                required
                                type='textarea'
                                rows={10}
                            />

                            <TextInput
                                label='OPT OUT'
                                name='opt_out'
                                value={settings.opt_out}
                                onChange={handleChnage}
                                required
                                type='textarea'
                                rows={10}
                            />

                            <TextInput
                                label='Voicemail Welcome'
                                name='vm_welcome'
                                value={settings.vm_welcome}
                                onChange={handleChnage}
                                required
                                type='textarea'
                                rows={10}
                            />

                            <TextInput
                                label='Callback Welcome'
                                name='cb_welcome'
                                value={settings.cb_welcome}
                                onChange={handleChnage}
                                required
                                type='textarea'
                                rows={10}
                            />
                        </div>

                        <ShortCodes
                            codes={[
                                { code: 'phonenumber_show_callerid_phonenumber', label: 'phonenumber_show_callerid_phonenumber', desc: 'Boolean used to show/hide the CallerId (CNAM) and the phonenumber' },
                                { code: 'phonenumber_show_transcript_of_call', label: 'phonenumber_show_transcript_of_call', desc: 'Boolean used to show/hide the call transcript' },
                                { code: 'phonenumber_show_original_transcript', label: 'phonenumber_show_original_transcript', desc: 'Boolean used to show/hide the call transcript' },
                                { code: 'business_name', label: 'business_name', desc: 'Campaign Business Name' },
                            ]}

                        />
                    </div>


                    <Button
                        text={`Save`}
                        className='ml-auto'
                        action={saveMutation.mutate}
                        loading={saveMutation.isLoading}
                    />
                </Card>

            </Animate>
        </>
    )
}

export default VM_CB