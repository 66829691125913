import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Card, Link, Table, useNavigate, useView } from 'components/lib'
import React from 'react'

function Emails() {
    const navigate = useNavigate()
    const view = useView()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['all', 'email'],
        queryFn: () => axios.get('/api/email')
    })

    const handleDelete = (data) => {
        if (data.template === 'MAIN') {
            return view.notification.show('MAIN Template cannot be deleted, you can only Edit it', 'error')
        }

        view.modal.show({
            title: 'Delete Email',
            form: {},
            buttonText: 'Yes, delete',
            text: `Are you sure you want to delete "${data.template}" email?`,
            url: `/api/email/${data.id}`,
            method: 'DELETE',
            destructive: true,
        }, () => refetch());
    }

    return (
        <Animate loading={isLoading}>
            <Card>
                <div className="flex flex-col mb-6 gap-2">
                    <Link
                        url='/emails/create'
                        text='Create Email'
                    />
                    <Link
                        url='/emails/send'
                        text='Send Email To Users'
                    />
                </div>

                <Table
                    search
                    data={data?.data?.data}
                    loading={isLoading}
                    show={['template', 'subject', 'from', 'created_at']}
                    actions={{
                        edit: (data) => navigate(`/emails/${data.id}/edit`),
                        delete: handleDelete
                    }}
                />
            </Card>
        </Animate>
    )
}

export default Emails