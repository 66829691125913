import { Card, Label, TextInput } from 'components/lib';
import React, { useState, useEffect } from 'react'

export function Log({ log }) {
    const [form, setForm] = useState(null)

    useEffect(() => {
        if (!log.data) return;

        const f = {};

        Object.keys(log.data).forEach(key => {
            f[key] = {
                label: key,
                value: log.data[key],
                type: key === 'body' ? 'textarea' : 'text',
            }
        });

        setForm(f);


    }, [log.data])

    return (
        <Card loading={log.loading}>

            {
                Object.keys(form || {}).map(key => (
                    key === 'body' ?
                        <div>
                            <Label text='Body' />
                            <pre className='overflow-x-auto mb-4'>
                                {JSON.stringify(JSON.parse(form[key].value), null, 2)}
                            </pre>
                        </div>

                        : form[key].value && <TextInput
                            key={key}
                            label={form[key].label}
                            value={form[key].value}
                        />
                ))
            }

        </Card>
    );
}
