import React, { useState } from 'react'
import { Animate, Button, Card, ModalContainer, Select, Table, TextInput, TitleRow, useNavigate, useView } from 'components/lib'
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { countries, states, timeZones } from 'helper/states';

function Campaigns() {
  const view = useView()
  const navigate = useNavigate()

  const statuses = [
    { value: 'active', color: 'green' },
    { value: 'deleted', color: 'red' },
    { value: 'past due', color: 'orange' },
    { value: 'delinquent', color: 'purple' },
    { value: 'suspended', color: 'red' },
    { value: 'billing issue', color: 'yellow' },
    { value: 'cancelled', color: 'gray' },
  ]

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['campaigns'],
    queryFn: () => axios.get('/api/campaigns')
  })

  const [edit, setEdit] = useState({})
  const editMutation = useMutation({
    mutationKey: ['campaigns', edit?.id],
    mutationFn: (data) => axios.put(`/api/campaigns/${data.id}`, data),
    onSuccess: () => {
      view.notification.show(`Campaign ${edit.campaign_name} was updated`, 'success', true);
      setEdit({})
      refetch()
    },
    onError: (error) => {
      view.handleError(error)
    }
  })

  const editCampaign = (data) => {
    return setEdit(data)
  }

  const deleteCampaign = (data) => {
    view.modal.show({
      title: `Delete Campaign ${data.name}`,
      form: {},
      buttonText: 'Yes, delete',
      text: `Are you sure you want to delete "${data.campaign_name}" campaign?`,
      url: `/api/campaigns/${data.id}`,
      method: 'DELETE',
      destructive: true,
    }, () => {
      view.notification.show(`Campaign ${data.campaign_name} was deleted`, 'success', true);
      refetch()
    });
  }

  const viewCampaign = ({ id, user_id }) => {
    navigate(`/campaigns/${id}?user=${user_id}`)
  }

  // const voicemail = ({ id }) => {
  //   navigate(`/campaigns/${id}/vm_notifications`)
  // }

  // const callback = ({ id }) => {
  //   navigate(`/campaigns/${id}/cb_notifications`)
  // }

  const trunOnOffFeatures = ({ id, name }) => {
    navigate(`/campaigns/${id}/features?c=${name}`)
  }

  const inputs = {
    campaign_name: {
      type: 'text',
      label: 'Campaign Name',
      value: edit?.campaign_name,
      containerClass: 'col-span-2'
    },
    business_name: {
      type: 'text',
      label: 'Business Name',
      value: edit?.business_name,
      containerClass: 'col-span-2'
    },
    country: {
      type: 'select',
      label: 'Country',
      default: edit?.country,
      options: countries,
      containerClass: 'col-span-2'
    },
    callforwarding_number: {
      type: 'text',
      label: 'FWD Number',
      value: edit?.callforwarding_number
    },
    business_phonenumber: {
      type: 'text',
      label: 'Business Number',
      value: edit?.business_phonenumber
    },
    status: {
      label: 'Status',
      type: 'select',
      default: edit?.status,
      options: statuses.map(s => ({ value: s.value, label: s.value }))
    },
    timezone: {
      label: 'Timezone',
      type: 'select',
      default: edit?.timezone,
      options: timeZones[edit?.country]
    },
    street_address: {
      type: 'text',
      label: 'Street Address',
      value: edit?.street_address,
    },
    state: {
      type: 'select',
      label: 'State',
      default: edit?.state,
      options: states[edit?.country]
    },
    city: {
      type: 'text',
      label: 'City',
      value: edit?.city,

    },
    zip: {
      type: 'text',
      label: 'Zip',
      value: edit?.zip
    },
  }

  return (
    <Animate>
      <TitleRow title='All Campaigns'>
        {
          edit?.id && (
            <ModalContainer close={() => setEdit(null)}>
              <Card title={`Update Campaign ${edit.campaign_name}`} className='flex flex-col'>

                <div className="grid grid-cols-2 gap-2">
                  {
                    Object.keys(inputs).map((key, i) => {
                      const input = inputs[key]
                      if (input.type === 'text') {
                        return (
                          <TextInput
                            key={i}
                            name={key}
                            label={input.label}
                            type={input.type}
                            value={input.value}
                            containerClassName={input.containerClass}
                            onChange={(name, value) => setEdit(prev => ({ ...prev, [name]: value }))}
                          />
                        )
                      }
                      if (input.type === 'select') {
                        return (
                          <Select
                            key={i}
                            name={key}
                            label={input.label}
                            default={input.default}
                            containerClassName={input.containerClass}
                            options={input.options}
                            onChange={(name, value) => setEdit(prev => ({ ...prev, [name]: value }))}
                          />
                        )
                      }
                      return null
                    })
                  }

                </div>

                <div className="grid sm:grid-cols-2 gap-2 mt-2">
                  <Button
                    text='Close'
                    color='red'
                    className='!w-full'
                    action={() => setEdit(null)}
                    small
                  />
                  <Button
                    text='Save'
                    color='green'
                    className='!w-full'
                    action={() => {
                      editMutation.mutate({
                        id: edit.id,
                        campaign_name: edit.campaign_name,
                        business_name: edit.business_name,
                        country: edit.country,
                        callforwarding_number: edit.callforwarding_number,
                        business_phonenumber: edit.business_phonenumber,
                        status: edit.status,
                        timezone: edit.timezone,
                        street_address: edit.street_address,
                        state: edit.state,
                        city: edit.city,
                        zip: edit.zip
                      })
                    }}
                    small
                  />
                </div>
              </Card>
            </ModalContainer>
          )
        }
        <Card loading={isLoading}>
          <Table
            search
            data={data?.data?.campaigns}
            loading={isLoading}
            show={['campaign_name', 'business_address', 'FWD_number', 'plan', 'status', 'date_created']}
            badge={{
              col: 'status', color: 'gray', condition: statuses
            }}
            actions={{
              custom: [
                { icon: 'eye', action: viewCampaign },
                { icon: 'toggle-right', title: 'Turn OFF/ON Features', action: trunOnOffFeatures, },
              ],
              edit: editCampaign,
              delete: deleteCampaign,
            }}
          />
        </Card>
      </TitleRow>
    </Animate>
  )
}

export default Campaigns