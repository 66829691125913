import React from 'react'
import Nav from './Nav'
import { Animate, Card, Table, useAPI } from 'components/lib'

function MarketingLeads() {
    const { data, loading } = useAPI('/api/marketing/leads')

    return (
        <>
            <Nav />

            <Animate>
                <Card loading={loading}>
                    <Table
                        search
                        loading={loading}
                        data={data?.leads}
                    />
                </Card>
            </Animate>
        </>
    )
}

export default MarketingLeads