import { Form } from 'components/lib'
import React from 'react'
import { useParams } from 'react-router-dom'

export default function T_M_P_M(props) {
    const { id } = useParams()

    return (
        <div>
            <p className='text-sm mb-4'>If the new text messages per month amount is bigger than the total sent messages the susbpended campaign will be changed to active.</p>
            <Form
                title='Text essages Per Month'
                inputs={{
                    T_M_P_M: {
                        type: 'number',
                        label: 'Text essages Per Month',
                        required: true,
                        value: props.T_M_P_M
                    }
                }}
                buttonText='Save'
                url={`/api/campaign/${id}/T_M_P_M`}
                method='PUT'
            />
        </div>
    )
}
