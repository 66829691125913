import { Animate, Icon } from 'components/lib'
import React, { useState } from 'react'

export function PopOver(props) {
    const [showPopOver, setShowPopOver] = useState(false)

    return (
        <div className='md:relative flex'>
            <div
                className='cursor-help'
                onMouseEnter={() => setShowPopOver(true)}
                onMouseLeave={() => setShowPopOver(false)}
            >
                <Icon
                    image='help-circle'
                    color='blue'
                />
            </div>

            {
                showPopOver && (
                    <Animate key={showPopOver} type='pop-center'>
                        <div className='text-sm shadow absolute md:-top-1 left-0 md:left-5 md:w-max md:max-w-[25rem] bg-white border rounded-md px-2 p-1 z-10'>
                            {props.text}
                        </div>
                    </Animate>
                )
            }
        </div>
    )
}

