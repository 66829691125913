const Style = {

  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  small: 'py-0.5 px-1.5',
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  green: 'bg-green-500',
  orange: 'bg-orange-500',
  purple: 'bg-purple-500',
  yellow: 'bg-yellow-500',
  gray: 'bg-gray-500',
  other: 'bg-gray-500',
}

export default Style;