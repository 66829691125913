import { Animate, Card, Form, ShortCodes, useView } from 'components/lib'
import React from 'react'
import Nav from './Nav'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

function CoreSMSHandler() {
    const view = useView()

    const { data, isLoading,refetch } = useQuery({
        queryKey: ['core_sms_handler'],
        queryFn: () => axios.get("/api/settings/core_sms_handler")
    })

    return (
        <>
            <Nav />
            <Animate>
                <Card loading={isLoading}>
                    <div className="grid sm:grid-cols-2 gap-4">
                        <Form
                            inputs={{
                                opt_out_message: {
                                    label: 'OPT OUT MESSAGE',
                                    type: 'textarea',
                                    rows: 4,
                                    required: true,
                                    value: data?.data?.opt_out_message
                                },
                                opt_in_message: {
                                    label: 'OPT IN MESSAGE',
                                    type: 'textarea',
                                    rows: 4,
                                    required: true,
                                    value: data?.data?.opt_in_message
                                },
                                help_message: {
                                    label: 'HELP MESSAGE',
                                    type: 'textarea',
                                    rows: 4,
                                    required: true,
                                    value: data?.data?.help_message
                                },
                                info_message: {
                                    label: 'INFO MESSAGE',
                                    type: 'textarea',
                                    rows: 4,
                                    required: true,
                                    value: data?.data?.info_message
                                },
                                unknown_message: {
                                    label: 'UNKNOWN MESSAGE',
                                    type: 'textarea',
                                    rows: 4,
                                    required: true,
                                    value: data?.data?.unknown_message
                                },
                                gather_url: {
                                    label: 'GATHER URL',
                                    type: 'text',
                                    required: true,
                                    value: data?.data?.gather_url
                                },
                                gather_max_digits: {
                                    label: 'GATHER MAX DIGITS',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gather_max_digits
                                },
                                gather_digit_timeout_seconds: {
                                    label: 'GATHER DIGIT TIMEOUT SECONDS',
                                    type: 'number',
                                    required: true,
                                    value: data?.data?.gather_digit_timeout_seconds
                                }
                            }}
                            buttonText='Save'
                            url='/api/settings/core_sms_handler'
                            method='PUT'
                            callback={(res) => {
                                view.notification.show(res.data?.data?.message, 'success', true)
                                refetch()
                            }}
                        />
                        <ShortCodes
                            codes={[
                                { code: '{business_name}', label: 'Business Name', desc: '* Required, please add your business name to the message' },
                                { code: '{address}', label: 'Address', desc: 'Business address' },
                                { code: '{phone}', label: 'Phone Number', desc: 'Business phone number' },
                                { code: '{hours}', label: 'Hours', desc: 'Hours of operation' },
                                { code: '{city}', label: 'City', desc: 'Business City' },
                                { code: '{website_link}', label: 'Website Link', desc: 'Website link to your business website. All links will be shortened.' },
                                { code: '{working_hours_link}', label: 'Working Hours Link', desc: 'Link to your online ordering, menu, Calendly, or any other relevant information. All links will be shortened.' },
                                { code: '{working_hours_link_2}', label: 'Working Hours Link 2', desc: 'Any additional links you would like to provide? All links will be shortened.' },
                                { code: '{after_hours_link}', label: 'After Hours Link', desc: 'Link to your online ordering, menu, Calendly, or any other relevant information. All links will be shortened.' },
                                { code: '{after_hours_link_2}', label: 'After Hours Link 2', desc: 'Any additional links you would like to provide? All links will be shortened.' }
                            ]}
                        />
                    </div>
                </Card>
            </Animate>
        </>
    )
}

export default CoreSMSHandler