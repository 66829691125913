/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value
*
*   PROPS
*   value:  numeric value
*   label: string
*   loading: boolean to toggle loading animation (optional)
*   icon: icon to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import { Card, Icon, Loader, ClassHelper } from 'components/lib';
import Style from './stat.tailwind.js';

export function CustomStat(props) {
    // is loading
    if (props.loading) {
        return (
            <Card>
                <div className={Style.stat}>
                    <Loader />
                </div>
            </Card>
        );
    }

    const statStyle = ClassHelper(Style, {

        stat: true,
        className: props.className

    })

    return (
        <Card>
            <div className={statStyle}>

                {props.icon &&
                    <Icon
                        color='dark'
                        image={props.icon}
                        size={20}
                        className={Style.icon}
                    />
                }

                {props.children}

            </div>
        </Card>
    );
}