import { Animate, Card, Paginate, Search, Table, useAPI, useView } from 'components/lib'
import React, { useState } from 'react'

function Support() {
    // state 
    const [search, setSearch] = useState('');
    const [offset, setOffset] = useState(0);

    // show 25 results at a time
    const limit = 25;

    const { data, loading, refetch } = useAPI(`/api/support?search=${search}&offset=${offset}&limit=${limit}`);


    const view = useView()

    const deleteSupport = (data, callback) => {
        view.modal.show({
            title: 'Delete Support Message',
            form: {},
            buttonText: 'Delete',
            text: `Are you sure you want to delete ${data.email} support message?`,
            url: `/api/support/${data.id}`,
            method: 'DELETE',
            destructive: true,

        }, () => {
            view.notification.show(`Support message from ${data.email} was deleted`, 'success', true);
            callback();
        });
    }

    const solveSupport = (data, callback) => {
        if (data.solved) return
        view.modal.show({
            title: 'Mark as solved',
            form: {},
            buttonText: 'Yes mark as solved',
            text: 'Are you sure you want to mark this message as solved?',
            url: `/api/support/${data.id}/solve`,
            method: 'PUT',
            destructive: true,
        }, () => {
            view.notification.show('Message marked as solved', 'success', true);
            refetch()
        });
    }

    return (
        <Animate loading={loading}>
            <Card loading={loading}>
                <Search throttle={1000} callback={x => setSearch(x)} /><br />

                <Paginate
                    offset={offset}
                    limit={limit}
                    total={data?.total}
                    loading={loading}
                    onChange={x => setOffset(x)}
                />

                <Table
                    loading={loading}
                    data={data?.results}
                    show={['message', 'status', 'email', 'business_contact', 'date']}
                    badge={{
                        col: 'status', color: 'red', condition: [
                            { value: 'solved', color: 'green' },
                            { value: 'unsolved', color: 'red' },
                        ]
                    }}
                    actions={{
                        email: true,
                        delete: deleteSupport,
                        custom: [{ icon: 'check', action: solveSupport }]
                    }}
                />

            </Card>
        </Animate>
    )
}

export default Support