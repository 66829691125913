/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import Style from './header.module.scss';
import { useAuth } from 'app/auth';

export function Header(props) {
  const { user } = useAuth()

  return (
    <header className={Style.header}>

      <div className='flex items-center'>
        {props.title && <h1>{props.title}</h1>}
        {props.children}


        {
          user.fullname && <span className='ml-auto text-gray-400 font-light'>
            Hello, {user.fullname}
          </span>
        }
      </div>

    </header>

  );
}
