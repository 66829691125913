import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Button, Card, Checkbox, EmailInput, PhoneInput, TextInput, useAPI, useView } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function Notifications() {
    const { id } = useParams()
    const view = useView()

    const [cbSettings, setcbSettings] = useState({})
    const [secondEmail, setSecondEmail] = useState(false)

    const { data, loading } = useAPI(`/api/campaign/${id}/cb_notifications_settings`)

    useEffect(() => {
        if (data?.cbSettings) {
            setcbSettings(data.cbSettings)

            if (cbSettings.second_email) {
                setSecondEmail(true)
            }
        }
    }, [data])

    const handleChnage = ({ name, value }) => {
        setcbSettings(prev => ({
            ...prev, [name]: value,
            ...(name === 'email_notifications' && value === false) && { emails: [] },
            ...(name === 'phonenumber_notifications' && value === false) && { phonenumber: [] },
        }))
    }

    const saveMutation = useMutation(
        [id, 'cb_notifications_settings'],
        () => axios.put(`/api/campaign/${id}/cb_notifications_settings`, cbSettings).then(res => res.data.data)
    )

    useEffect(() => {
        if (saveMutation.isSuccess) {
            view.notification.show(saveMutation.data.message, 'success', true)
        }
    }, [saveMutation.isSuccess])

    return (
        <Animate>
            <div className="mb-5">
                <h1 className='text-xl font-semibold'>Call Back Request Settings</h1>
                <p>You will have full access to your user portal where you can see, add notes, and complete call back requests.  You have the options here to send notifications to your staff when you receive a call back request.</p>
            </div>

            <div className="grid lg:grid-cols-2 items-start gap-6">
                <Card loading={loading} title={`Call Back Request Email Notification Settings`}>
                    <h3 className='font-semibold mb-2'>Would you like to receive email notifications for call back requests?</h3>
                    <div className="flex flex-col gap-y-1 mb-4">
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="email_notifications"
                                checked={!cbSettings.email_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: false })}
                            />
                            NO
                        </label>
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="email_notifications"
                                checked={cbSettings.email_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: true })}
                            />
                            YES
                        </label>
                    </div>

                    {
                        Boolean(cbSettings.email_notifications) === true && (
                            <>
                                <div className="grid items-center sm:grid-cols-2 gap-x-4 gap-y-1 mb-4">
                                    <EmailInput
                                        required
                                        label='Email Address'
                                        name='email'
                                        onChange={(name, value) => handleChnage({ name, value })}
                                        value={cbSettings.email}
                                    />

                                    <div>
                                        <Button
                                            icon={secondEmail ? 'x' : 'plus'}
                                            alignCenter
                                            text={`${secondEmail ? 'Remove' : 'Add'} Second Email`}
                                            action={() => setSecondEmail(prev => {
                                                if (prev === true) {
                                                    handleChnage({ name: 'second_email', value: null })
                                                }
                                                return !prev
                                            })}
                                            small
                                        />
                                    </div>
                                </div>

                                {
                                    (secondEmail || cbSettings.second_email) ? (
                                        <div className="grid sm:grid-cols-2 mb-6">
                                            <EmailInput
                                                required
                                                label='Second Email Address'
                                                name='second_email'
                                                onChange={(name, value) => handleChnage({ name, value })}
                                                value={cbSettings.second_email}
                                            />
                                        </div>
                                    ) : null
                                }

                                <p className='text-sm mb-2'>
                                    If none of the items are checked below you will receive a email with a secure link to log in to the web portal to view your Visual Voicemails. <strong>* Please note the options below are not HIPPA compliant. Please uncheck the boxes below if used for medical.</strong>
                                </p>

                                <div className="flex flex-col gap-y-1 mb-6">
                                    {
                                        emailOptions.map(i => (
                                            <Checkbox
                                                key={i.label}
                                                option={i.label}
                                                name={i.name}
                                                checked={cbSettings[i.name]}
                                                onChange={(name, value) => handleChnage({ name, value })}
                                            />
                                        ))
                                    }
                                </div>
                            </>
                        )
                    }

                </Card>
                <Card loading={loading} title={`Call Back Request Text Message Notifications Settings`}>
                    <h3 className='font-semibold mb-2'>Would you like to receive text messages for callback requests?</h3>
                    <div className="flex flex-col gap-y-1 mb-4">
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="phonenumber_notifications"
                                checked={!cbSettings.phonenumber_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: false })}
                            />
                            NO
                        </label>
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="phonenumber_notifications"
                                checked={cbSettings.phonenumber_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: true })}
                            />
                            YES
                        </label>
                    </div>

                    {
                        Boolean(cbSettings.phonenumber_notifications) === true && (
                            <>
                                <div className="grid sm:grid-cols-2 mb-4">
                                    <PhoneInput
                                        required
                                        label='Phone number to text'
                                        name='phonenumber'
                                        onChange={(name, value) => handleChnage({ name, value })}
                                        value={cbSettings.phonenumber}
                                    />
                                </div>


                                <p className='text-sm mb-2'>
                                    If non of the items are checked below you will receive a text message with a secure link to log in to the web portal to view your Visual Voicemails. <strong>* Please note the options below are not HIPPA compliant. Please uncheck the boxes below if  you are using for medical.</strong>
                                </p>

                                <div className="flex flex-col gap-y-1 mb-6">
                                    {
                                        phonenumberOptions.map(i => (
                                            <Checkbox
                                                key={i.label}
                                                option={i.label}
                                                name={i.name}
                                                checked={cbSettings[i.name]}
                                                onChange={(name, value) => handleChnage({ name, value })}
                                            />
                                        ))
                                    }
                                </div>

                            </>
                        )
                    }
                </Card>
            </div>

            <Button
                text={`Save`}
                className='ml-auto'
                action={saveMutation.mutate}
                loading={saveMutation.isLoading}
            />
        </Animate>
    )
}

export default Notifications


const emailOptions = [
    {
        name: 'email_show_callerid_phonenumber',
        label: '*Caller ID name & phone number'
    }
]

const phonenumberOptions = [
    {
        name: 'phonenumber_show_callerid_phonenumber',
        label: '*Caller ID name & phone number'
    }
]
