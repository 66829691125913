import { useAPI, Log } from 'components/lib'
import React from 'react'
import { useParams } from 'react-router-dom'

function LinkLogs() {
    const { log_id } = useParams()

    const log = useAPI(`/api/campaign/link_log/${log_id}`)


    return (
        <Log log={log} />
    )
}

export default LinkLogs