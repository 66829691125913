import { SubNav } from 'components/lib'
import React from 'react'

function Nav() {
    return (
        <SubNav items={[
            { link: '/marketing/leads', label: 'Leads' },
        ]} 
        />
    )
}

export default Nav