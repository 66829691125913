import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Button, Label, TextInput, useView } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function CustomShortCode() {
    const { id } = useParams()
    const view = useView()
    const [logo, setLogo] = useState(null)

    const { data, isSuccess, refetch } = useQuery({
        queryKey: ['campaign', id, 'customShortCode'],
        queryFn: () => axios.get(`/campaign/${id}/customShortCode`).then(res => res.data),
    })

    useEffect(() => {
        if (data && isSuccess) {
            setCustoms({
                logo: data.logo || null,
                text: data.text || '',
                description: data.description || '',
                background: data.background || '#14263a',
                color: data.color || '#ffffff'
            })
        }

    }, [data, isSuccess])

    const [customs, setCustoms] = useState({})


    const saveM = useMutation({
        mutationKey: ['campaign', id, 'customShortCode'],
        mutationFn: (data) => axios.post(`/campaign/${id}/customShortCode`, data).then(res => res.data),
    })

    useEffect(() => {
        if (saveM.isError) {
            view.notification.show(saveM.error?.response?.data?.message || saveM.error.message, 'error')
        }
    }, [saveM.isError])

    useEffect(() => {
        if (saveM.isSuccess) {
            view.notification.show(saveM.data?.message, 'success', true)
        }
    }, [saveM.isSuccess])

    const resetM = useMutation({
        mutationKey: ['campaign', id, 'customShortCode'],
        mutationFn: () => axios.delete(`/campaign/${id}/customShortCode`).then(res => res.data),
    })

    useEffect(() => {
        if (resetM.isError) {
            view.notification.show(resetM.error?.response?.data?.message || resetM.error.message, 'error')
        }
    }, [resetM.isError])

    useEffect(() => {
        if (resetM.isSuccess) {
            view.notification.show(resetM.data?.message, 'success', true)
            refetch()
        }
    }, [resetM.isSuccess])

    return (
        <form encType={'multipart/form-data'} className="grid gap-4">

            <div>
                <Label text='Logo' />
                <input
                    type="file"
                    accept='image/*'
                    onChange={e => setLogo(e.target.files[0])}
                />
                {
                    (logo || customs?.logo) && (
                        <div className='bg-gray-200'>
                            <img
                                src={logo ? URL.createObjectURL(logo) : customs?.logo || ''}
                                className='mx-auto w-full h-full max-w-[350px] object-cover mt-2 rounded'
                                alt='logo'
                            />
                        </div>
                    )
                }
            </div>
            <TextInput
                label="Text"
                name='text'
                value={customs?.text}
                onChange={(name, value) => setCustoms({ ...customs, [name]: value })}
            />
            <TextInput
                label="Description"
                type="textarea"
                name='description'
                value={customs?.description}
                onChange={(name, value) => setCustoms({ ...customs, [name]: value })}
            />
            <div>
                <Label text='Background' />
                <input
                    type="color"
                    value={customs?.background}
                    onChange={e => setCustoms({ ...customs, background: e.target.value })}
                />
            </div>
            <div>
                <Label text='color' />
                <input
                    type="color"
                    value={customs?.color}
                    onChange={e => setCustoms({ ...customs, color: e.target.value })}
                />
            </div>

            <Button
                text='Save'
                small
                loading={saveM.isLoading}
                action={() => {
                    const formData = new FormData()
                    if (logo) {
                        formData.append('logo', logo)
                    }
                    formData.append('text', customs.text)
                    formData.append('description', customs.description)
                    formData.append('background', customs.background)
                    formData.append('color', customs.color)

                    saveM.mutate(formData)
                }}
            />

            <Button
                text='Reset Default'
                color='red'
                small
                loading={resetM.isLoading}
                action={() => resetM.mutate()}
            />

        </form>
    )
}
