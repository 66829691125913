import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Button, Card, Checkbox, EmailInput, PhoneInput, useAPI, useView } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function Notifications() {
    const { id } = useParams()
    const view = useView()

    const [vmSettings, setVmSettings] = useState({})
    const [secondEmail, setSecondEmail] = useState(false)

    const { data, loading } = useAPI(`/api/campaign/${id}/vm_notifications_settings`)

    useEffect(() => {
        if (data?.vmSettings) {
            setVmSettings(data.vmSettings)

            if (data.vmSettings.second_email) {
                setSecondEmail(true)
            }
        }
    }, [data])

    const handleChnage = ({ name, value }) => {
        setVmSettings(prev => ({
            ...prev, [name]: value,
            ...(name === 'email_notifications' && value === false) && { email: [] },
            ...(name === 'phonenumber_notifications' && value === false) && { phonenumber: [] },
        }))
    }

    const saveMutation = useMutation(
        [id, 'vm_notifications_settings'],
        () => axios.put(`/api/campaign/${id}/vm_notifications_settings`, vmSettings).then(res => res.data.data)
    )

    useEffect(() => {
        if (saveMutation.isSuccess) {
            view.notification.show(saveMutation.data.message, 'success', true)
        }
    }, [saveMutation.isSuccess])


    useEffect(() => {
        if (saveMutation.isError) {
            view.handleError(saveMutation.error)
        }
    }, [saveMutation.isError])

    return (
        <Animate>
            <div className="mb-5">
                <h1 className='text-xl font-semibold'>Voicemail Notifications Settings</h1>
                <p>You will have full access to your user portal where you can see all of your voicemails, add notes, and complete the voicemail task.  You have the options here to send notifications to your staff when you receive a voicemail.</p>
            </div>

            <div className="grid lg:grid-cols-2 items-start gap-6">
                <Card loading={loading} title={`Voicemail Email Notification Settings`}>
                    <div className="mb-6">
                        <h2 className='text-lg font-semibold'>Voicemail Notification Settings</h2>
                        <p className="text-sm">
                            Lets set up how you would like to receive your voicemails. What email or emails + would you like the notifications to go to. What would you like to see attached in the email notification.
                        </p>
                    </div>

                    <h3 className='font-semibold mb-2'>Would you like to receive email notifications for voicemails?</h3>
                    <div className="flex flex-col gap-y-1 mb-4">
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="email_notifications"
                                checked={!vmSettings.email_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: false })}
                            />
                            NO
                        </label>
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="email_notifications"
                                checked={vmSettings.email_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: true })}
                            />
                            YES
                        </label>
                    </div>

                    {
                        Boolean(vmSettings.email_notifications) === true && (
                            <>
                                <div className="grid items-center sm:grid-cols-2 gap-x-4 gap-y-1 mb-4">
                                    <EmailInput
                                        required
                                        label='Email Address'
                                        name='email'
                                        onChange={(name, value) => handleChnage({ name, value })}
                                        value={vmSettings.email}
                                    />

                                    <div>
                                        <Button
                                            icon={secondEmail ? 'x' : 'plus'}
                                            alignCenter
                                            text={`${secondEmail ? 'Remove' : 'Add'} Second Email`}
                                            action={() => setSecondEmail(prev => {
                                                if (prev === true) {
                                                    handleChnage({ name: 'second_email', value: null })
                                                }
                                                return !prev
                                            })}
                                            small
                                        />
                                    </div>
                                </div>

                                {
                                    (vmSettings.second_email || secondEmail) ? (
                                        <div className="grid sm:grid-cols-2 mb-6">
                                            <EmailInput
                                                required
                                                label='Second Email Address'
                                                name='second_email'
                                                onChange={(name, value) => handleChnage({ name, value })}
                                                value={vmSettings.second_email}
                                            />
                                        </div>
                                    ) : null
                                }

                                <p className='text-sm mb-2'>
                                    If none of the items are checked below you will receive a email with a secure link to log in to the web portal to view your Visual Voicemails. <strong>* Please note the options below are not HIPPA compliant. Please uncheck the boxes below if used for medical.</strong>
                                </p>

                                <div className="flex flex-col gap-y-1 mb-6">
                                    {
                                        emailOptions.map(i => (
                                            <Checkbox
                                                key={i.label}
                                                option={i.label}
                                                name={i.name}
                                                checked={vmSettings[i.name]}
                                                onChange={(name, value) => handleChnage({ name, value })}
                                            />
                                        ))
                                    }
                                </div>

                                <div className="mb-3">
                                    <h3 className='font-semibold'>Translate Transcripts</h3>
                                    <p className='text-sm'>If we receive a voicemail in a different language, other than English, we will translate and transcribe the voice message into English. We can also attached the original language transcript.</p>
                                </div>
                                <div className="flex flex-col gap-y-1">
                                    <Checkbox
                                        option={'*Attach Original language transcript'}
                                        name={'email_attach_original_language_transcript'}
                                        checked={vmSettings.email_attach_original_language_transcript}
                                        onChange={(name, value) => handleChnage({ name, value })}
                                    />
                                    <Checkbox
                                        option={'*Original transcript'}
                                        name={'email_show_original_transcript'}
                                        checked={vmSettings.email_show_original_transcript}
                                        onChange={(name, value) => handleChnage({ name, value })}
                                    />
                                </div>
                            </>
                        )
                    }

                </Card>
                <Card loading={loading} title={`Voicemail Text Message Notification Settings`}>
                    <div className="mb-6">
                        <h2 className='text-lg font-semibold'>Text Message Notification Settings</h2>
                        <p className="text-sm">
                            Lets set up how you would like to receive your voicemails. If you would like to  receive your voicemails in a text message format please add the phone number you would like them sent to.
                        </p>
                    </div>

                    <h3 className='font-semibold mb-2'>Would you like to receive text message notifications?</h3>
                    <div className="flex flex-col gap-y-1 mb-4">
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="phonenumber_notifications"
                                checked={!vmSettings.phonenumber_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: false })}
                            />
                            NO
                        </label>
                        <label className='flex items-center gap-1'>
                            <input
                                type="radio"
                                name="phonenumber_notifications"
                                checked={vmSettings.phonenumber_notifications}
                                onChange={(e) => handleChnage({ name: e.target.name, value: true })}
                            />
                            YES
                        </label>
                    </div>

                    {
                        Boolean(vmSettings.phonenumber_notifications) === true && (
                            <>
                                <div className="grid sm:grid-cols-2 mb-4">
                                    <PhoneInput
                                        required
                                        label='Phone number to text'
                                        name='phonenumber'
                                        onChange={(name, value) => handleChnage({ name, value })}
                                        value={vmSettings.phonenumber}
                                    />
                                </div>


                                <p className='text-sm mb-2'>
                                    If non of the items are checked below you will receive a text message with a secure link to log in to the web portal to view your Visual Voicemails. <strong>* Please note the options below are not HIPPA compliant. Please uncheck the boxes below if  you are using for medical</strong>
                                </p>

                                <div className="flex flex-col gap-y-1 mb-6">
                                    {
                                        phonenumberOptions.map(i => (
                                            <Checkbox
                                                key={i.label}
                                                option={i.label}
                                                name={i.name}
                                                checked={vmSettings[i.name]}
                                                onChange={(name, value) => handleChnage({ name, value })}
                                            />
                                        ))
                                    }
                                </div>


                            </>
                        )
                    }
                </Card>
            </div>

            <Button
                text={`Save`}
                className='ml-auto'
                action={saveMutation.mutate}
                loading={saveMutation.isLoading}
            />
        </Animate>
    )
}

export default Notifications


const emailOptions = [
    {
        name: 'email_show_callerid_phonenumber',
        label: '*Caller ID name & phone number'
    },
    {
        name: 'email_show_transcript_of_call',
        label: '*Transcript of the call'
    },
    {
        name: 'email_attach_voicemail_audiofile',
        label: '*Attach voicemail audio file'
    }
]

const phonenumberOptions = [
    {
        name: 'phonenumber_show_callerid_phonenumber',
        label: '*Caller ID name & phone number'
    },
    {
        name: 'phonenumber_show_transcript_of_call',
        label: '*Transcript of the call'
    }
]
