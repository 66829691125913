import { useEffect, useRef } from 'react';
import io from 'socket.io-client';

export const useSocket = () => {
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_CORE_SMS_SERVER); 

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const emit = (eventName, data) => {
        if (socketRef.current) {
            socketRef.current.emit(eventName, data);
        }
    };

    const on = (eventName, callback) => {
        if (socketRef.current) {
            socketRef.current.on(eventName, callback);
        }
    };

    return { emit, on };
};