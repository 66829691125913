import {Card, Form, useView} from "components/lib"
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Verticals} from "../new";

export default function NewCampaignBrand() {
    const {id} = useParams()
    const view = useView()
    const navigate = useNavigate()


    return (
        <Card>
            <div className={'mb-6'}>
                <Link to={`/10dlc/brands/${id}/campaigns`} className={'text-blue-400 underline'}>Back</Link>
            </div>

            <Form
                inputs={{
                    Vertical: {
                        label: 'Vertical',
                        type: 'select',
                        required: true,
                        options: Verticals
                    }
                }}
                inputsClassName={'grid grid-cols-2 gap-2'}
                buttonText='Create Campaign'
                url={`/api/10dlc/brands/${id}/campaigns/new`}
                method='POST'
                callback={(res) => {
                    if (res.data.campaign) {
                        return navigate(`/10dlc/brands/${id}/campaigns`)
                    }

                    return view.notification.show(`Failed to create campaign for brand ${id}, please check our inputs.`, 'error')
                }}
            />
        </Card>
    )
}
