import {Animate, Card, Paginate, SubNav, Table, useAPI} from 'components/lib'
import React, {useState} from 'react'
import {useParams, useNavigate, useSearchParams} from 'react-router-dom'
import CampaignStats from './CampaignStats'
import UserInfo from './UserInfo'

function Campaign() {
    const {id} = useParams()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const [logsOffset, setLogsOffset] = useState(0)
    const logsLimit = 50

    const [linkLogsOffset, setLinkLogsOffset] = useState(0)
    const linkLogsLimit = 50

    const [phoneNumberErrorOffset, setPhoneNumberErrorOffset] = useState(0)
    const phoneNumberErrorLimit = 50

    const {data, loading} = useAPI(`/api/campaigns/${id}`)
    const logs = useAPI(`/api/campaigns/${id}/logs?offset=${logsOffset}&limit=${logsLimit}`)
    const linkLogs = useAPI(`/api/campaigns/${id}/linklogs?offset=${logsOffset}&limit=${logsLimit}`)
    const phoneNumberErrorLogs = useAPI(`/api/campaigns/${id}/phoneNumberErrorLogs?offset=${phoneNumberErrorOffset}&limit=${phoneNumberErrorLimit}`)
    const numberProvisioningLogs = useAPI(`/api/campaigns/${id}/numberProvisioningLogs`)

    const [activeTab, setActiveTab] = useState('logs')

    const tabs = [
        {action: () => setActiveTab('logs'), label: 'logs', data: logs},
        {action: () => setActiveTab('opt_out'), label: 'opt_out'},
        {action: () => setActiveTab('links'), label: 'links'},
        {
            action: () => setActiveTab('link_logs'),
            label: 'link_logs',
            actions: {custom: [{icon: 'eye', action: (data) => navigate(`/campaigns/${id}/link_log/${data.id}`)}]}
        },
        {action: () => setActiveTab('phone_number_error_logs'), label: 'phone_number_error_logs'},
        {action: () => setActiveTab('number_provisioning_logs'), label: 'number_provisioning_logs'},
    ]

    const tableData = activeTab === 'logs'
        ? logs?.data?.results
        : activeTab === 'link_logs' ? linkLogs?.data?.results
            : activeTab === 'phone_number_error_logs' ? phoneNumberErrorLogs?.data?.results
            : activeTab === 'number_provisioning_logs' ? numberProvisioningLogs?.data?.results
                : data?.[activeTab]

    return (
        <Animate loading={loading}>

            <UserInfo id={searchParams.get('user')}/>

            <CampaignStats
                loading={loading}
                campaign={id}
                name={data?.campaign?.name}
                callforwarding_number={data?.campaign?.callforwarding_number}
            />

            <Card loading={loading}>
                <SubNav
                    active={activeTab}
                    items={tabs}
                />

                <Animate key={activeTab}>

                    {
                        activeTab === 'logs' && (
                            <Paginate
                                offset={logsOffset}
                                limit={logsLimit}
                                total={logs?.data?.total}
                                loading={logs.loading}
                                onChange={x => setLogsOffset(x)}
                            />
                        )
                    }

                    {
                        activeTab === 'link_logs' && (
                            <Paginate
                                offset={linkLogsOffset}
                                limit={linkLogsLimit}
                                total={linkLogs?.data?.total}
                                loading={linkLogs.loading}
                                onChange={x => setLinkLogsOffset(x)}
                            />
                        )
                    }

                    {
                        activeTab === 'phone_number_error_logs' && (
                            <Paginate
                                offset={phoneNumberErrorOffset}
                                limit={phoneNumberErrorLimit}
                                total={phoneNumberErrorLogs?.data?.total}
                                loading={phoneNumberErrorLogs.loading}
                                onChange={x => setPhoneNumberErrorOffset(x)}
                            />
                        )
                    }

                    {
                        ['logs', 'link_logs', 'links', 'opt_out', 'phone_number_error_logs', 'number_provisioning_logs'].includes(activeTab) && (
                            <Table
                                search
                                loading={loading}
                                show={Object.keys(tableData?.[0] || {}).filter(x => x !== 'id')}
                                data={tableData}
                                actions={tabs.find(tab => tab.label === activeTab)?.actions}
                                badge={{
                                    col: 'type', color: 'other', condition: [
                                        {value: 'STOP', color: 'red'},
                                        {value: 'START', color: 'green'},
                                        {value: 'HELP', color: 'yellow'},
                                        {value: 'INFO', color: 'orange'},
                                        {value: 'OPT_IN', color: 'green'},
                                        {value: 'OPT_OUT', color: 'red'},
                                    ]
                                }}
                            />
                        )
                    }
                </Animate>
            </Card>
        </Animate>
    )
}

export default Campaign