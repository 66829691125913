import React from 'react'
import Nav from './Nav'
import { Animate, Card, Form, useView } from 'components/lib'
import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'

function Aws() {
    const view = useView()

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['aws'],
        queryFn: () => Axios.get("/api/settings/aws")
    })

    return (
        <>
            <Nav />
            <Animate>
                <Card className='lg:max-w-4xl' loading={isLoading}>
                    <Form
                        title='Missed call sms Settings'
                        inputs={{
                            polly_voice: {
                                type: 'select',
                                label: 'Polly Voice',
                                options: voices.map(v => ({ label: v, value: v })),
                                default: data?.data?.polly_voice
                            }

                        }}
                        buttonText='Save'
                        url='/api/settings/aws'
                        method='PUT'
                        callback={(res) => {
                            view.notification.show(res.data?.data?.message, 'success', true)
                            refetch()
                        }}
                    />
                </Card>
            </Animate>
        </>
    )
}

const voices = [
    'Ivy', 'Joanna', 'Kendra', 'Kimberly', 'Sali', 'Joey', 'Justin', 'Matthew',
]

export default Aws