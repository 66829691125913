import { Signin } from 'views/signin';
import { Dashboard } from 'views/dashboard';
import { Accounts } from 'views/accounts';
import { Users } from 'views/users';
import UsersSettings from 'views/settings/Users';
import { Logs } from 'views/log/list';
import { LogDetail } from 'views/log/detail';
import { EventGroups } from 'views/event/groups';
import { Events } from 'views/event/list';
import { EventDetail } from 'views/event/detail';
import Coupons from 'views/coupons';
import { NotFound } from 'views/404';
import AfterHoursSms from 'views/settings/AfterHoursSms';
import Aws from 'views/settings/Aws';
import WorkingHoursSms from 'views/settings/WorkingHoursSms';
import Campaigns from 'views/campaigns';
import Campaign from 'views/campaigns/campaign';
import UserCampaigns from 'views/campaigns/userCampaigns';
import Gpt from 'views/settings/Gpt';
import PlanOptions from 'views/settings/PlanOptions';
import CoreSMSHandler from 'views/settings/CoreSMSHandler';
import LinkLogs from 'views/campaigns/LinkLogs';
import Errors from 'views/Errors';
import { ErrorDetail } from 'views/Errors/details';
import SupportedTypes from 'views/settings/SupportedTypes';
import MarketingLeads from 'views/marketing/MarketingLeads';
import Email from 'views/email';
import CreateEmail from 'views/email/createEmail';
import EditEmail from 'views/email/editEmail';
import SendEmail from 'views/email/sendEmail';
import Support from 'views/support';
import Shortlinks from 'views/shortlinks';
import Shortlink from 'views/shortlinks/shortlink';
import { ShortLinkLogDetail } from 'views/shortlinks/details';
import VM_Notifications from 'views/campaigns/VM_Notifications';
import CB_Notifications from 'views/campaigns/CB_Notifications';
import VM_CB from 'views/settings/VM_CB';
import Features from 'views/campaigns/Features';
import Brands from 'views/10dlc/brands';
import NewBrand from 'views/10dlc/brands/new';
import BrandCampaigns from 'views/10dlc/brands/campaigns';
import NewBrandCampaign from 'views/10dlc/brands/campaigns/new';
import TeenDlc from 'views/settings/10dlc';

const Routes = [
  {
    path: '/',
    view: Signin,
    layout: 'auth',
    title: 'Mission Control'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Mission Control'
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'master',
    title: 'Mission Control'
  },
  {
    path: '/errors',
    view: Errors,
    layout: 'app',
    permission: 'master',
    title: 'Errors'
  },
  {
    path: '/errors/:id',
    view: ErrorDetail,
    layout: 'app',
    permission: 'master',
    title: 'Error Details'
  },
  {
    path: '/accounts',
    view: Accounts,
    layout: 'app',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/users',
    view: Users,
    layout: 'app',
    permission: 'master',
    title: 'Users'
  },
  {
    path: '/support',
    view: Support,
    layout: 'app',
    permission: 'master',
    title: 'Support'
  },
  {
    path: '/logs',
    view: Logs,
    layout: 'app',
    permission: 'master',
    title: 'App Logs'
  },
  {
    path: '/logs/:id',
    view: LogDetail,
    layout: 'app',
    permission: 'master',
    title: 'Log Detail'
  },
  {
    path: '/events',
    view: EventGroups,
    layout: 'app',
    permission: 'master',
    title: 'Events'
  },
  {
    path: '/events/:group',
    view: Events,
    layout: 'app',
    permission: 'master',
    title: 'Event'
  },
  {
    path: '/events/:group/:id',
    view: EventDetail,
    layout: 'app',
    permission: 'master',
    title: 'Event Detail'
  },
  {
    path: '/notfound',
    view: NotFound,
    layout: 'app',
    permission: 'master',
    title: 'Not Found'
  },
  {
    path: '/coupons',
    view: Coupons,
    layout: 'app',
    permission: 'master',
    title: 'Coupons'
  },
  {
    path: '/settings/workinghourssms',
    view: WorkingHoursSms,
    layout: 'app',
    permission: 'master',
    title: 'Working Hours SMS Settings'
  },
  {
    path: '/settings/afterhourssms',
    view: AfterHoursSms,
    layout: 'app',
    permission: 'master',
    title: 'After hours missed call SMS Settings'
  },
  {
    path: '/settings/aws',
    view: Aws,
    layout: 'app',
    permission: 'master',
    title: 'AWS Settings'
  },
  {
    path: '/settings/gpt',
    view: Gpt,
    layout: 'app',
    permission: 'master',
    title: 'Gpt Settings'
  },
  {
    path: '/settings/plan_options',
    view: PlanOptions,
    layout: 'app',
    permission: 'master',
    title: 'Plan Options'
  },
  {
    path: '/settings/core_sms_handler',
    view: CoreSMSHandler,
    layout: 'app',
    permission: 'master',
    title: 'Core SMS Handler'
  },
  {
    path: '/settings/supported_types',
    view: SupportedTypes,
    layout: 'app',
    permission: 'master',
    title: 'Supported Types'
  },
  {
    path: '/settings/vm_cb_settings',
    view: VM_CB,
    layout: 'app',
    permission: 'master',
    title: 'Voicemail & Callback'
  },
  {
    path: '/settings/users',
    view: UsersSettings,
    layout: 'app',
    permission: 'master',
    title: 'Users'
  },
  {
    path: '/settings/10dlc',
    view: TeenDlc,
    layout: 'app',
    permission: 'master',
    title: '10 DLC'
  },
  {
    path: '/campaigns',
    view: Campaigns,
    layout: 'app',
    permission: 'master',
    title: 'Campaigns'
  },
  {
    path: '/campaigns/user/:id',
    view: UserCampaigns,
    layout: 'app',
    permission: 'master',
    title: 'User'
  },
  {
    path: '/campaigns/:id',
    view: Campaign,
    layout: 'app',
    permission: 'master',
    title: 'Campaign Analytics'
  },
  {
    path: '/campaigns/:id/link_log/:log_id',
    view: LinkLogs,
    layout: 'app',
    permission: 'master',
    title: 'Campaign Link Logs'
  },
  {
    path: '/campaigns/:id/vm_notifications',
    view: VM_Notifications,
    layout: 'app',
    permission: 'master',
    title: 'Campaign Voicemail Settings'
  },
  {
    path: '/campaigns/:id/cb_notifications',
    view: CB_Notifications,
    layout: 'app',
    permission: 'master',
    title: 'Campaign Callback Settings'
  },
  {
    path: '/campaigns/:id/features',
    view: Features,
    layout: 'app',
    permission: 'master',
    title: 'Features'
  },
  {
    path: '/marketing/leads',
    view: MarketingLeads,
    layout: 'app',
    permission: 'master',
    title: 'Marketing Leads'
  },
  {
    path: '/emails',
    view: Email,
    layout: 'app',
    permission: 'master',
    title: 'Emails'
  },
  {
    path: '/emails/create',
    view: CreateEmail,
    layout: 'app',
    permission: 'master',
    title: 'Create Email'
  },
  {
    path: '/emails/:id/edit',
    view: EditEmail,
    layout: 'app',
    permission: 'master',
    title: 'Edit Email'
  },
  {
    path: '/emails/send',
    view: SendEmail,
    layout: 'app',
    permission: 'master',
    title: 'Send Email'
  },
  {
    path: '/short_links',
    view: Shortlinks,
    layout: 'app',
    permission: 'master',
    title: 'Short Links'
  },
  {
    path: '/short_link/:id',
    view: Shortlink,
    layout: 'app',
    permission: 'master',
    title: 'Short Link Logs'
  },
  {
    path: '/short_link/log/:id',
    view: ShortLinkLogDetail,
    layout: 'app',
    permission: 'master',
    title: 'Log'
  },
  {
    path: '/10dlc/brands',
    view: Brands,
    layout: 'app',
    permission: 'master',
    title: '10DLC Brands'
  },
  {
    path: '/10dlc/brands/new',
    view: NewBrand,
    layout: 'app',
    permission: 'master',
    title: 'New 10DLC Brand'
  },
  {
    path: '/10dlc/brands/:id/campaigns',
    view: BrandCampaigns,
    layout: 'app',
    permission: 'master',
    title: 'Brand Campaigns'
  },
  {
    path: '/10dlc/brands/:id/campaigns/new',
    view: NewBrandCampaign,
    layout: 'app',
    permission: 'master',
    title: 'New Brand Campaigns'
  },
]

export default Routes;