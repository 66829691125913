/***
*
*   LOG/DETAIL
*   View the details of an application log.
*
**********/

import { useState, useEffect } from 'react';
import { Card, Label, TextInput, useAPI, useLocation } from 'components/lib';

export function LogDetail(props) {

  const location = useLocation();
  const path = location?.pathname?.split('/');

  const [form, setForm] = useState(null);
  const log = useAPI(`/api/log/${path[2]}`)

  useEffect(() => {
    if (!log.data) return;

    const f = {};

    Object.keys(log.data?.[0]).forEach(key => {
      f[key] = {
        label: key,
        value: log.data[0][key],
        type: key === 'body' ? 'textarea' : 'text',
      }
    });

    setForm(f);


  }, [log.data])

  return (
    <Card loading={log.loading}>

      {
        Object.keys(form || {}).map(key => (
          key === 'body' ?
            <div>
              <Label text='Body' />
              <pre className='overflow-x-auto mb-4'>
                {JSON.stringify(JSON.parse(form[key].value), null, 2)}
              </pre>
            </div>

            : form[key].value && <TextInput
              key={key}
              label={form[key].label}
              value={form[key].value}
            />
        ))
      }

    </Card>
  );
}