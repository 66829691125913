import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Button, Card, Checkbox, Label, PopOver, useView } from 'components/lib'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import CustomShortCode from './CustomShortCode'
import T_M_P_M from './T_M_P_M'
import Notes from './Notes'

function Features() {
    const { id } = useParams()
    const [searchParams] = useSearchParams()

    useLayoutEffect(() => {
        document
            .getElementsByTagName('header')[0]
            .getElementsByTagName('h1')[0]
            .innerText = `${searchParams.get('c') || ''} Campaign Features`

    }, [searchParams])


    const view = useView()

    const { data, isLoading, refetch } = useQuery(
        ['campaigns', id, 'features'],
        () => axios.get(`/api/campaign/${id}/features`).then(res => res.data?.data)
    )

    const [features, setFeatures] = useState(data)

    const putFeatures = useMutation(
        ['campaigns', id, 'features'],
        () => axios.put(`/api/campaign/${id}/features`, features).then(res => res.data?.data)
    )

    useEffect(() => {
        if (data) {
            setFeatures(data)
        }
    }, [data])

    useEffect(() => {
        if (putFeatures.isSuccess) {
            view.notification.show(putFeatures.data?.message, 'success', true)
        }
    }, [putFeatures.isSuccess])

    useEffect(() => {
        if (!features?.auto_sms && !features?.auto_vm && !features?.auto_cb) {
            setFeatures({
                ...features,
                voice_greeting: true
            })
        }

    }, [features?.auto_sms, features?.auto_vm, features?.auto_cb])

    const [whFile, setWhFile] = useState(null)
    const [ahFile, setAhFile] = useState(null)
    const [confirmationFile, setConfirmationFile] = useState(null)

    const uploadFiles = useMutation(
        ['campaigns', id, 'custom_greeting_files'],
        (data) => axios.post(`/api/campaign/${id}/custom_greeting_files`, data).then(res => res.data?.data)
    )

    useEffect(() => {
        if (uploadFiles.isError) {
            view.notification.show(uploadFiles.error?.response?.data?.message || uploadFiles.error.message, 'error')
        }
    }, [uploadFiles.isError])

    useEffect(() => {
        if (uploadFiles.isSuccess) {
            view.notification.show(uploadFiles.data?.message, 'success', true)
            setWhFile(null)
            setAhFile(null)
            refetch()
        }
    }, [uploadFiles.isSuccess])

    const removeGreeting = useMutation(
        ['campaigns', id, 'remove_custom_greeting_file'],
        (data) => axios.post(`/api/campaign/${id}/remove_custom_greeting_file/${data.type}`, data).then(res => res.data?.data)
    )

    useEffect(() => {
        if (removeGreeting.isError) {
            view.notification.show(removeGreeting.error?.response?.data?.message || removeGreeting.error.message, 'error')
        }
    }, [removeGreeting.isError])

    useEffect(() => {
        if (removeGreeting.isSuccess) {
            view.notification.show(removeGreeting.data?.message, 'success', true)
            refetch()
        }
    }, [removeGreeting.isSuccess])

    const handleFueaturesChange = (name, checked) => {
        setFeatures(prev => {
            let newFeatures = { ...prev }

            return ({
                ...newFeatures,
                [name]: checked,
                ...name === 'auto_sms' && { auto_vm: false, auto_cb: false, voicemail: false, callback: false, voice_greeting: false, text_messages: true },
                ...name === 'auto_vm' && { auto_sms: false, auto_cb: false, voicemail: true, callback: false, voice_greeting: false, text_messages: false },
                ...name === 'auto_cb' && { auto_sms: false, auto_vm: false, voicemail: false, callback: true, voice_greeting: false, text_messages: false },
            })
        })
    }

    return (
        <Animate loading={isLoading}>
            <div>
                <div className="grid lg:grid-cols-2 gap-x-4">
                    <Card loading={isLoading} title='Turn ON/OFF Campaign Features'>
                        <div className='flex flex-col gap-4'>
                            <Label
                                text='Set Auto Features'
                                popOver='You can only enable one auto at a time, this will make all other features (including the greeting) to disapear and only show the related settings for the selected auto.'
                                className='mt-6'
                            />
                            {
                                [
                                    {
                                        label: 'Auto SMS',
                                        name: 'auto_sms',
                                        type: 'checkbox',
                                        value: features?.auto_sms,
                                    },
                                    {
                                        label: 'Auto VM',
                                        name: 'auto_vm',
                                        type: 'checkbox',
                                        value: features?.auto_vm,
                                    },
                                    {
                                        label: 'Auto CB',
                                        name: 'auto_cb',
                                        type: 'checkbox',
                                        value: features?.auto_cb,
                                    }
                                ].map(item => (
                                    <Checkbox
                                        key={item.label}
                                        option={item.label}
                                        name={item.name}
                                        type={item.type}
                                        checked={item.value}
                                        onChange={handleFueaturesChange}
                                        disabled={item.disabled}
                                    />
                                ))
                            }

                            <Label
                                text='Set Text Messages & Greeting'
                                // popOver='Text Messages or Voice Greeting is always enabled as part of the base plan and have no (ADD NOW BUTTON) this will lead to alwas show the card unless you have one Auto Feature, (you cannot disable voice greeting unless you have enabled one Auto feature)'
                                className='mt-6'
                            />
                            {
                                [
                                    {
                                        label: 'Text Messages (always enabed when Auto SMS is on to send the text message to the caller.)',
                                        name: 'text_messages',
                                        type: 'checkbox',
                                        value: features?.text_messages,
                                        disabled: features?.auto_sms
                                    },
                                    {
                                        label: 'Voice Greeting (always enabed when no Auto Feature is on to welcome the caller.)',
                                        name: 'voice_greeting',
                                        type: 'checkbox',
                                        value: features?.voice_greeting,
                                        disabled: !(features?.auto_sms || features?.auto_vm || features?.auto_cb)
                                    }
                                ].map(item => (
                                    <Checkbox
                                        key={item.label}
                                        option={item.label}
                                        name={item.name}
                                        type={item.type}
                                        checked={item.value}
                                        onChange={handleFueaturesChange}
                                        disabled={item.disabled}
                                    />
                                ))
                            }

                            <Label
                                text='Set Voicemail or Callback'
                                popOver={`Disabling Voicemail or callback will show the (ADD NOW BUTTON) cuz it's in the add ones plan and can be added or removed by user, enabling it will add the feature to the user campaign directly. (the settings card is always visible unless you have Auto Feature enabled then only reated card will be shown based on if you select Auto VM or Auto CB)`}
                                className='mt-6'
                            />
                            {
                                [
                                    {
                                        label: 'VoiceMail (always ON when Auto VM enabled)',
                                        name: 'voicemail',
                                        type: 'checkbox',
                                        value: features?.voicemail,
                                        disabled: features?.auto_vm
                                    },
                                    {
                                        label: 'CallBack (always ON when Auto CB enabled)',
                                        name: 'callback',
                                        type: 'checkbox',
                                        value: features?.callback,
                                        disabled: features?.auto_cb
                                    }
                                ].map(item => (
                                    <Checkbox
                                        key={item.label}
                                        option={item.label}
                                        name={item.name}
                                        type={item.type}
                                        checked={item.value}
                                        onChange={handleFueaturesChange}
                                        disabled={item.disabled}
                                    />
                                ))
                            }

                            <Button
                                text={'Save'}
                                loading={putFeatures.isLoading}
                                action={putFeatures.mutate}
                                small
                            />
                        </div>
                    </Card>

                    <Card loading={isLoading} title='Custom Greeting Files'>
                        <form
                            encType={'multipart/form-data'}
                            className='grid gap-6'
                        >
                            <div className='grid gap-2'>
                                <label>Working Hours Greeting File</label>
                                <input
                                    name='wh_voice_file'
                                    type='file'
                                    accept='audio/mp3,audio/mpeg'
                                    onChange={(e) => {
                                        setWhFile(e.target.files[0])
                                    }}
                                />

                                {/* preview the selected audio */}
                                {
                                    (whFile || (features?.wh_voice_file && features?.wh_custom_file_set)) && (
                                        <audio
                                            className='w-full'
                                            controls
                                            src={whFile ? URL.createObjectURL(whFile) : features?.wh_voice_file}
                                        />
                                    )
                                }
                                {
                                    features?.wh_custom_file_set && (
                                        <Button
                                            className='w-fit'
                                            color='red'
                                            small
                                            text='Remove'
                                            loading={removeGreeting.isLoading}
                                            action={() => removeGreeting.mutate({ type: 'wh' })}
                                        />
                                    )
                                }
                            </div>

                            <div className='grid gap-2'>
                                <label>After Hours Greeting File</label>
                                <input
                                    name='ah_voice_file'
                                    type='file'
                                    accept='audio/mp3,audio/mpeg'
                                    onChange={(e) => {
                                        setAhFile(e.target.files[0])
                                    }}
                                />
                                {
                                    (ahFile || (features?.ah_voice_file && features?.ah_custom_file_set)) && (
                                        <audio
                                            className='w-full'
                                            controls
                                            src={ahFile ? URL.createObjectURL(ahFile) : features?.ah_voice_file}
                                        />
                                    )
                                }
                                {
                                    features?.ah_custom_file_set && (
                                        <Button
                                            className='w-fit'
                                            color='red'
                                            small
                                            text='Remove'
                                            loading={removeGreeting.isLoading}
                                            action={() => removeGreeting.mutate({ type: 'ah' })}
                                        />
                                    )
                                }
                            </div>

                            <div className='grid gap-2'>
                                <label>Confirmation File</label>
                                <input
                                    name='confirmation_voice_file'
                                    type='file'
                                    accept='audio/mp3,audio/mpeg'
                                    onChange={(e) => {
                                        setConfirmationFile(e.target.files[0])
                                    }}
                                />
                                {
                                    (confirmationFile || features?.confirmation_voice_file) && (
                                        <audio
                                            className='w-full'
                                            controls
                                            src={confirmationFile ? URL.createObjectURL(confirmationFile) : features?.confirmation_voice_file}
                                        />
                                    )
                                }
                                {
                                    features?.confirmation_voice_file && (
                                        <Button
                                            className='w-fit'
                                            color='red'
                                            small
                                            text='Remove'
                                            loading={removeGreeting.isLoading}
                                            action={() => removeGreeting.mutate({ type: 'confirmation' })}
                                        />
                                    )
                                }
                            </div>

                            <Button
                                text={'Upload'}
                                loading={uploadFiles.isLoading}
                                small
                                action={() => {
                                    if (whFile || ahFile || confirmationFile) {
                                        const formData = new FormData()

                                        whFile && formData.append('wh_voice_file', whFile)
                                        ahFile && formData.append('ah_voice_file', ahFile)
                                        confirmationFile && formData.append('confirmation_voice_file', confirmationFile)

                                        uploadFiles.mutate(formData)
                                    }
                                }}
                            />
                        </form>
                    </Card>

                    <Card loading={isLoading} title='Short Code Page Customization'>
                        <CustomShortCode />
                    </Card>

                    <Card loading={isLoading} title='Text Messages Per Month'>
                        <T_M_P_M T_M_P_M={data?.T_M_P_M} />
                    </Card>

                    <Card loading={isLoading} title='Notes Settings'>
                        <Notes notes_editable={data?.notes_editable} />
                    </Card>
                </div>


            </div>
        </Animate>
    )
}

export default Features