import { TitleRow } from 'components/lib'
import React, { useEffect, useState } from 'react'

export function ShortCodes({ codes }) {
    const [copiedCode, setCopiedCode] = useState('')

    const copyCode = (code) => {
        navigator.clipboard.writeText(code)
        setCopiedCode(code)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCopiedCode('')
        }, 2000)

        return () => clearTimeout(timeout)
    }, [copiedCode])

    return (
        <TitleRow title='Short codes' hint='(Click code to copy)'>
            <div className='flex flex-col gap-3'>
                {
                    codes.map((c, i) => (
                        <div onClick={() => copyCode(c.code)} key={i} className='cursor-pointer'>
                            <div>
                                <span className='font-semibold'>{c.label}</span>
                                {copiedCode === c.code && <span className='text-green-500 ml-2 text-sm'>Copied!</span>}
                            </div>
                            <p className='capitalize'>{c.desc}</p>
                        </div>
                    ))
                }
            </div>
        </TitleRow>
    )
}
