/***
*
*   LOG/DETAIL
*   View the details of an application log.
*
**********/

import { useState, useEffect } from 'react';
import { Card, Label, TextInput, useAPI } from 'components/lib';
import { useParams } from 'react-router-dom';

export function ShortLinkLogDetail(props) {
    const { id } = useParams()

    const [form, setForm] = useState(null);
    const log = useAPI(`/api/shortlink/log/${id}`)

    useEffect(() => {
        if (!log.data) return;

        const f = {};

        Object.keys(log.data?.log).forEach(key => {
            f[key] = {
                label: key,
                value: log.data.log[key],
                type: key === 'body' ? 'textarea' : 'text',
            }
        });

        setForm(f);


    }, [log.data])

    return (
        <Card loading={log.loading}>

            {
                Object.keys(form || {}).map(key => (
                    key === 'body' ?
                        <div>
                            <Label text='Body' />
                            <pre className='overflow-x-auto mb-4'>
                                {JSON.stringify(JSON.parse(form[key].value), null, 2)}
                            </pre>
                        </div>

                        : form[key].value && <TextInput
                            key={key}
                            label={form[key].label}
                            value={form[key].value}
                        />
                ))
            }

        </Card>
    );
}