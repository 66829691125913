import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Card, useView } from 'components/lib'
import React from 'react'

function UserInfo({ id }) {
    const view = useView()

    const userData = useQuery(
        ['user', id],
        () => axios.get(`/api/user/${id}`).then(res => res.data?.data),
        {
            retry: false,
            onError: (e) => view.handleError(e)
        }
    )

    return (
        <Card loading={userData.isLoading}>
            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
                <div>
                    <div className='mb-2 pb-2 border-b border-b-gray-300'>
                        <h3>CONTACT</h3>
                        <h4 className='text-xl capitalize font-semibold'>{userData.data?.firstname + ' ' + userData.data?.lastname}</h4>
                    </div>


                    <div className='grid grid-cols-2 pt-2 gap-6'>
                        {
                            [
                                { label: 'PHONE', value: userData.data?.phonenumber },
                                { label: 'EMAIL', value: userData.data?.email },
                                { label: 'ADDRESS', value: userData.data?.business_address, className: 'col-span-2' },
                            ].map(i => (
                                <div key={i.label} className={`${i.className} flex flex-col break-words`}>
                                    <span className='text-sm'>{i.label}</span>
                                    <span className='font-semibold'>{i.value}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div>
                    <div className='mb-2 pb-2 border-b border-b-gray-300'>
                        <span className='text-xl invisible'>{'_'}</span>
                        <h3>PROFILE SNAPSHOT</h3>
                    </div>

                    <div className='grid grid-cols-2 pt-2 gap-6'>
                        {
                            [
                                { label: 'ACCOUNT CREATED', value: userData.data?.date_created },
                                { label: 'TOTAL CAMPAIGNS', value: userData.data?.total_campaigns },
                                { label: 'LAST ACTIVE', value: userData.data?.last_active },
                                { label: 'ACTIVE CAMPAIGNS', value: userData.data?.active_campaigns },
                                { label: 'BUSINESS CATEGORY', value: userData.data?.business_category },
                            ].map(i => (
                                <div key={i.label} className='flex flex-col'>
                                    <span className='text-sm'>{i.label}</span>
                                    <span className='font-semibold'>{i.value}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>


                <div>
                    <div className='mb-2 pb-2 border-b border-b-gray-300'>
                        <span className='text-xl invisible'>{'_'}</span>
                        <h3>GROWTH</h3>
                    </div>

                    <div className='grid grid-cols-2 pt-2 gap-6'>
                        {
                            [
                                { label: 'MMR', value: `$376.00` },
                                { label: 'YRR', value: `$4,512.00` },
                            ].map(i => (
                                <div key={i.label} className='flex flex-col'>
                                    <span className='text-sm'>{i.label}</span>
                                    <span className='font-semibold text-lg'>{i.value}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default UserInfo