import React from 'react'
import Nav from './Nav'
import { Animate, Card, Form, useAPI, useView } from 'components/lib'

function Users() {
  const { data, loading } = useAPI('/api/settings/users')
  const view = useView()

  return (
    <>
      <Nav />

      <Animate>
        <Card loading={loading}>
          <Form
            inputs={{
              user_price: {
                type: 'text',
                label: 'Users Seats Price ID',
                value: data?.settings?.user_price
              },
              price_per_user: {
                type: 'number',
                label: 'Price per user in $',
                value: data?.settings?.price_per_user
              },
              free_users: {
                type: 'number',
                label: 'Free users',
                value: data?.settings?.free_users
              },
            }}
            buttonText='Save'
            url='/api/settings/users'
            method='PUT'
            callback={(res) => {
              view.notification.show(res.data?.data?.message, 'success', true)
            }}
          />
        </Card>
      </Animate>
    </>
  )
}

export default Users