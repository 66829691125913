/***
*
*   IMAGE
*   Import the image before passing it to this component
*
*   PROPS
*   source: imported source
*   alt: alt description
*   title: description
*   className: inject a custom class object
*
**********/

import { ClassHelper } from 'components/lib';
import Style from './image.tailwind.js';
import Img from 'react-image-webp';

export function Image(props) {

  const imageStyle = ClassHelper(Style, props);

  return (
    <Img
      src={props.source}
      webp={props.source || props.webp}
      alt={props.alt}
      title={props.title}
      className={imageStyle}
    />
  );
}