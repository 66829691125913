import React from 'react'
import Nav from './Nav'
import { Animate, Card, Form, useView } from 'components/lib'
import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'

function AfterHoursSms() {
  const view = useView()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['afterhourssms'],
    queryFn: () => Axios.get('/api/settings')
  })

  return (
    <>
      <Nav />

      <Animate>
        <Card className='lg:max-w-4xl' loading={isLoading}>
          <Form
            title='Missed call sms Settings'
            inputs={{
              afterhours_sms: {
                label: 'After hours missed call sms',
                type: 'textarea',
                required: true,
                rows: 4,
                value: data?.data?.settings?.afterhours_sms,
              },
              afterhours_footer: {
                label: 'After hours missed call footer',
                type: 'textarea',
                rows: 2,
                value: data?.data?.settings?.afterhours_footer,
              },
              afterhours_sms_message_limit: {
                label: 'After hours SMS message limit',
                type: 'number',
                required: true,
                value: data?.data?.settings?.afterhours_sms_message_limit,
              },
              afterhours_voice_message: {
                label: 'After hours missed call voice message',
                type: 'textarea',
                required: true,
                rows: 4,
                value: data?.data?.settings?.afterhours_voice_message,
              },
              afterhours_voice_message_footer: {
                label: 'After hours missed call voice message footer',
                type: 'textarea',
                rows: 2,
                value: data?.data?.settings?.afterhours_voice_message_footer,
              },
              afterhours_voice_message_limit: {
                label: 'After hours voice message limit',
                type: 'number',
                required: true,
                value: data?.data?.settings?.afterhours_voice_message_limit,
              }
            }}

            buttonText='Save'
            url='/api/settings/afterhourssms'
            method='PUT'
            callback={(res) => {
              view.notification.show(res.data?.data?.message, 'success', true)
              refetch()
            }}
          />
        </Card>
      </Animate>
    </>
  )
}

export default AfterHoursSms