/***
*
*   HOME LAYOUT
*   Main website layout
*
**********/

import { Fragment } from 'react';
import { HomeNav, useLocation } from 'components/lib';

export function HomeLayout(props){

  const location = useLocation();
  const transparent = location?.pathname === '/' ? true : false;

  return (
    <Fragment>
      <main className='home'style={{
        ...process.env.REACT_APP_SERVER === 'DEV' && { marginTop: '2.5rem' }
      }}>

        <HomeNav transparent={ transparent } />
        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
