import { Animate, Card, Table, TitleRow, useAPI } from 'components/lib'
import React from 'react'
import { useParams } from 'react-router-dom'

function Shortlink() {
    const { id } = useParams()

    const { data, loading } = useAPI(`/api/shortlink/${id}/logs`)


    return (
        <Animate>
            <Card loading={loading}>
                <TitleRow title='Logs for' >
                    <h2 className='font-semibold text-lg'>{data?.link?.short_url} <br /> {data?.link?.original_url}</h2>
                </TitleRow>

                <Table
                    data={data?.logs}
                    show={['body', 'created_at']}
                    actions={{
                        view: { url: '/short_link/log', col: 'id' },
                    }}
                />

            </Card>
        </Animate>
    )
}

export default Shortlink