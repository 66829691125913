import {useQuery} from "@tanstack/react-query"
import axios from "axios"
import {Button, Card, Table} from "components/lib"
import {useNavigate} from "react-router-dom";

export default function Brands() {
    const navigate = useNavigate()

    const {data, isLoading} = useQuery({
        queryKey: ['10dlc', 'brands'],
        queryFn: () => axios.get('/api/10dlc/brands').then(res => res.data),
        refetchOnWindowFocus: true
    })

    return (
        <div>
            <div className="mb-4">
                <Button
                    text='New Brand'
                    className='ml-auto'
                    color='blue'
                    goto='/10dlc/brands/new'
                    small
                />
            </div>

            <Card>
                <Table
                    loading={isLoading}
                    data={data?.brands}
                    show={['CompanyName', 'Vertical', 'BrandId', 'Email', 'Phone', 'IdentityStatus']}
                    badge={{
                        col: 'IdentityStatus', color: 'gray', condition: [
                            {value: 'VERIFIED', color: 'green'}
                        ]
                    }}
                    actions={{
                        custom: [
                            {
                                icon: 'eye',
                                title: 'campaigns',
                                action: (data) => navigate(`/10dlc/brands/${data.BrandId}/campaigns`),
                            }
                        ]
                    }}
                />
            </Card>
        </div>
    )
}