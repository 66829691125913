import Nav from "./Nav";
import React from "react";
import {Animate, Card, Form, useView} from "../../components/lib";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";

export default function TeenDlc() {
    const view = useView()

    const {data, isLoading} = useQuery({
        queryKey: ['settings', '10dlc'],
        queryFn: () => axios.get('/api/settings/10dlc').then(res => res.data)
    })

    const settings = data?.settings || {}

    return (
        <>
            <Nav/>

            <Animate>
                <Card loading={isLoading}>
                    <Form
                        inputs={{
                            EntityType: {
                                label: 'Entity Type',
                                type: 'select',
                                required: true,
                                options: [
                                    {label: 'Private Profit', value: 'PRIVATE_PROFIT'},
                                    {label: 'Public Profit', value: 'PUBLIC_PROFIT'},
                                    {label: 'Non Profit', value: 'NON_PROFIT'},
                                    {label: 'Government', value: 'GOVERNMENT'},
                                    {label: 'Sole Proprietor', value: 'SOLE_PROPRIETOR'},
                                ],
                                default: settings.EntityType,
                            },
                            BrandRelationship: {
                                label: 'Brand Relationship',
                                type: 'select',
                                required: true,
                                options: [
                                    {label: 'Basic Account', value: 'BASIC_ACCOUNT'},
                                    {label: 'Small Account', value: 'SMALL_ACCOUNT'},
                                    {label: 'Medium Account', value: 'MEDIUM_ACCOUNT'},
                                    {label: 'Large Account', value: 'LARGE_ACCOUNT'},
                                    {label: 'Key Account', value: 'KEY_ACCOUNT'},
                                ],
                                default: settings.BrandRelationship,
                            },
                            Usecase: {
                                label: 'Campaign Usecase',
                                type: 'select',
                                required: true,
                                options: [
                                    {label: 'Customer care', value: 'CUSTOMER_CARE'},
                                    {label: '2FA', value: '2FA'}
                                ],
                                default: settings.Usecase,
                            },
                            AltBusinessId: {
                                label: 'Alternate Business ID',
                                type: 'text',
                                value: settings.AltBusinessId,
                            },
                            AltBusinessIdType: {
                                label: 'Alternate Business ID Type',
                                type: 'text',
                                value: settings.AltBusinessIdType,
                            },
                            ResellerId: {
                                label: 'Reseller ID',
                                type: 'text',
                                value: settings.ResellerId,
                            },
                            Description: {
                                label: 'Description',
                                type: 'textarea',
                                rows: 6,
                                value: settings.Description,
                            },
                            SubscriberOptIn: {
                                option: 'Subscriber Opt-In',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.SubscriberOptIn),
                            },
                            OptinKeywords: {
                                label: 'Opt-In Keywords',
                                type: 'text',
                                value: settings.OptinKeywords,
                            },
                            OptinMessage: {
                                label: 'Opt-In Message',
                                type: 'textarea',
                                rows: 6,
                                value: settings.OptinMessage,
                            },
                            SubscriberOptOut: {
                                option: 'Subscriber Opt-Out',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.SubscriberOptOut),
                            },
                            OptoutKeywords: {
                                label: 'Opt-Out Keywords',
                                type: 'text',
                                value: settings.OptoutKeywords,
                            },
                            OptoutMessage: {
                                label: 'Opt-Out Message',
                                type: 'textarea',
                                rows: 6,
                                value: settings.OptoutMessage,
                            },
                            SubscriberHelp: {
                                option: 'Subscriber Help',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.SubscriberHelp),
                            },
                            HelpKeywords: {
                                label: 'Help Keywords',
                                type: 'text',
                                value: settings.HelpKeywords,
                            },
                            HelpMessage: {
                                label: 'Help Message',
                                type: 'textarea',
                                rows: 6,
                                value: settings.HelpMessage,
                            },
                            Sample1: {
                                label: 'Sample 1',
                                type: 'textarea',
                                rows: 6,
                                value: settings.Sample1,
                            },
                            Sample2: {
                                label: 'Sample 2',
                                type: 'textarea',
                                rows: 6,
                                value: settings.Sample2,
                            },
                            MessageFlow: {
                                label: 'Message Flow',
                                type: 'textarea',
                                rows: 6,
                                value: settings.MessageFlow,
                            },
                            AffiliateMarketing: {
                                label: 'Affiliate Marketing',
                                type: 'text',
                                value: settings.AffiliateMarketing,
                            },
                            ReferenceId: {
                                label: 'Reference ID',
                                type: 'text',
                                value: settings.ReferenceId,
                            },
                            AutoRenewal: {
                                option: 'Auto Renewal',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.AutoRenewal),
                            },
                            EmbeddedLink: {
                                option: 'Embedded Link',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.EmbeddedLink),
                            },
                            EmbeddedPhone: {
                                option: 'Embedded Phone',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.EmbeddedPhone),
                            },
                            NumberPool: {
                                option: 'Number Pool',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.NumberPool),
                            },
                            AgeGated: {
                                option: 'Age Gated',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.AgeGated),
                            },
                            DirectLending: {
                                option: 'Direct Lending',
                                type: 'checkbox',
                                defaultChecked: Boolean(settings.DirectLending),
                            }
                        }}
                        buttonText='Save'
                        url='/api/settings/10dlc'
                        method='PUT'
                        callback={res => {
                            view.notification.show(res.data?.data?.message, 'success', true)
                        }}
                    />
                </Card>
            </Animate>
        </>
    )
}