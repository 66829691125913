import { Animate, ClassHelper, Icon, Loader, PopOver } from 'components/lib';
import Style from './row.tailwind.js';
import { useState } from 'react';

export function TitleRow(props) {
  const titleRowStyle = ClassHelper(Style, {

    row: true,
    className: props.className

  })

  if (props.loading) {
    return (
      <div className={Style.loading}>
        <Loader />
      </div>
    );
  }

  return (
    <section className={titleRowStyle}>

      <div className='relative'>
        {
          (props.title || props.popOver) && (
            <div className='flex items-center gap-2 mb-2'>
              {
                props.title &&
                <h2 className={Style.title} >
                  {props.title}
                </h2>
              }
              {
                props.hint && (
                  <span className='text-sm'>{props.hint}</span>
                )
              }

              {
                props.popOver && <PopOver text={props.popOver} />
              }
            </div>

          )
        }

      </div>

      <div className={Style.actions}>
        {props.children}
      </div>

    </section>
  )
}