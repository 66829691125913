import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Animate, Button, Card, Label, Select, useAPI, useView } from 'components/lib'
import React, { useState } from 'react'
import ReactSelect from 'react-select'

function SendEmail() {
    const view = useView()

    const emails = useAPI('/api/email')
    const users = useAPI('/api/user')
    const [form, setForm] = useState({ template: '', users: [] })

    const saveEmailMutation = useMutation({
        mutationFn: () => axios.post('/api/email/send', form)
    })

    const handleSendEmail = async () => {
        try {
            const res = await saveEmailMutation.mutateAsync()
            view.notification.show(res?.data?.message, 'success', true)

        } catch (e) {
            view.handleError(e)
        }
    }

    return (
        <Animate loading={emails.loading || users.loading}>
            <Card>
                <Select
                    label='Email To Send'
                    options={emails?.data?.map(u => ({ value: u.template, label: u.template }))}
                    onChange={(_, value) => setForm(prev => ({ ...prev, template: value }))}
                />

                <div>
                    <Label text='Select Users' />
                    <ReactSelect
                        isMulti={true}
                        options={users?.data?.map(u => ({ label: u.email, value: u.email }))}
                        onChange={(values) => setForm(prev => ({ ...prev, users: values.map(v => v.value) }))}
                    />
                </div>

                <Button
                    className='mt-5'
                    text='Send'
                    small
                    action={handleSendEmail}
                    loading={saveEmailMutation.isLoading}
                    disabled={saveEmailMutation.isLoading}
                />
            </Card>
        </Animate>
    )
}

export default SendEmail