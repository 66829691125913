import { Animate, Card, Form, ShortCodes, TitleRow, useAPI, useNavigate } from 'components/lib'
import React, { useRef } from 'react'
import EmailEditor from 'react-email-editor'
import './style.scss'

function CreateEmail() {
    const editorRef = useRef(null)
    const navigate = useNavigate()

    const { data, loading } = useAPI(`/api/email/MAIN`)
    
    const getEmailHTML = () => new Promise((resolve) => {
        editorRef.current.editor.exportHtml((data) => {
            resolve(data)
        })
    })

    const loadMainDesign = () => { 
        editorRef.current?.loadDesign(data?.design)
    }

    return (
        <Animate loading={loading}>
            <Card>
                <EmailEditor
                    ref={editorRef}
                    onReady={loadMainDesign}
                />

                <div className='mt-5'>
                    <TitleRow title='Email Settings' />

                    <div className="grid grid-cols-2 gap-4">
                        <Form
                            inputs={{
                                template: {
                                    type: 'text',
                                    label: 'Template Name',
                                    placeholder: 'Template Name',
                                    value: '',
                                    required: true
                                },
                                subject: {
                                    type: 'text',
                                    label: 'Subject',
                                    placeholder: 'Subject',
                                    value: '',
                                    required: true
                                },
                                type: {
                                    type: 'select',
                                    label: 'Type',
                                    placeholder: 'Type',
                                    options: [
                                        { label: 'Critical', value: 'critical' },
                                        { label: 'Updates', value: 'updates' },
                                        { label: 'News', value: 'news' }
                                    ],
                                    required: true
                                },
                                from: {
                                    type: 'text',
                                    label: 'Email From',
                                    placeholder: 'Email From',
                                    value: '',
                                    required: true
                                }
                            }}
                            bodyPromise={getEmailHTML}
                            method='POST'
                            url='/api/email'
                            buttonText='Save Email'
                            callback={() => navigate('/emails')}
                        />
                        <ShortCodes
                            codes={[
                                { code: '{business_name}', label: 'Business Name', desc: '* Required, please add your business name to the message' },
                                { code: '{address}', label: 'Address', desc: 'Business address' },
                                { code: '{phone}', label: 'Phone Number', desc: 'Business phone number' },
                                { code: '{hours}', label: 'Hours', desc: 'Hours of operation' },
                                { code: '{city}', label: 'City', desc: 'Business City' },
                                { code: '{website_link}', label: 'Website Link', desc: 'Website link to your business website. All links will be shortened.' },
                                { code: '{working_hours_link}', label: 'Working Hours Link', desc: 'Link to your online ordering, menu, Calendly, or any other relevant information. All links will be shortened.' },
                                { code: '{working_hours_link_2}', label: 'Working Hours Link 2', desc: 'Any additional links you would like to provide? All links will be shortened.' },
                                { code: '{after_hours_link}', label: 'After Hours Link', desc: 'Link to your online ordering, menu, Calendly, or any other relevant information. All links will be shortened.' },
                                { code: '{after_hours_link_2}', label: 'After Hours Link 2', desc: 'Any additional links you would like to provide? All links will be shortened.' }
                            ]}
                        />
                    </div>
                </div>
            </Card>
        </Animate>
    )
}

export default CreateEmail