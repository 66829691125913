import React from 'react'
import Nav from './Nav'
import { Animate, Card, Form, useView } from 'components/lib'
import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'


function WorkingHoursSms() {
  const view = useView()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['workinghourssms'],
    queryFn: () => Axios.get('/api/settings')
  })

  return (
    <>
      <Nav />

      <Animate>
        <Card className='lg:max-w-4xl' loading={isLoading}>
          <Form
            title='Working hours sms Settings'
            inputs={{
              workinghours_sms: {
                label: 'Working hours sms',
                type: 'textarea',
                required: true,
                rows: 4,
                value: data?.data?.settings?.workinghours_sms,
              },
              workinghours_footer: {
                label: 'Working hours footer',
                type: 'textarea',
                rows: 2,
                value: data?.data?.settings?.workinghours_footer,
              },
              workinghours_sms_message_limit: {
                label: 'SMS message limit',
                type: 'number',
                required: true,
                value: data?.data?.settings?.workinghours_sms_message_limit,
              },
              workinghours_voice_message: {
                label: 'Working hours voice message',
                type: 'textarea',
                required: true,
                rows: 4,
                value: data?.data?.settings?.workinghours_voice_message,
              },
              workinghours_voice_message_footer: {
                label: 'Working hours voice message footer',
                type: 'textarea',
                rows: 2,
                value: data?.data?.settings?.workinghours_voice_message_footer,
              },
              workinghours_voice_message_limit: {
                label: 'Voice message limit',
                type: 'number',
                required: true,
                value: data?.data?.settings?.workinghours_voice_message_limit,
              }
            }}
            buttonText='Save'
            url='/api/settings/workinghourssms'
            method='PUT'
            callback={(res) => {
              view.notification.show(res.data?.data?.message, 'success', true)
              refetch()
            }}
          />
        </Card>
      </Animate>
    </>
  )
}

export default WorkingHoursSms