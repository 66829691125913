
import { useState, useEffect } from 'react';
import { Button, Card, Label, TextInput, useAPI, useLocation, useNavigate, useView } from 'components/lib';

export function ErrorDetail(props) {
  const view = useView()

  const location = useLocation();
  const path = location?.pathname?.split('/');
  const [form, setForm] = useState(null);
  const error = useAPI(`/api/error/${path[2]}`)

  const navigate = useNavigate()

  useEffect(() => {
    if (!error.data) return;

    const f = {};

    Object.keys(error.data?.[0]).forEach(key => {
      f[key] = {
        label: key,
        value: error.data[0][key],
        type: key === 'body' ? 'textarea' : 'text',
      }
    });

    setForm(f);
  }, [error.data])


  function solveError(data = error?.data?.[0]) {
    view.modal.show({
      title: 'Mark as solved',
      form: {},
      buttonText: 'Yes mark as solved',
      text: 'Are you sure you want to mark this error as solved?',
      url: `/api/error/${data.id}/solve`,
      method: 'PUT',
      destructive: true,

    }, () => {
      view.notification.show('error marked as solved', 'success', true);
      navigate('/errors')
    });
  }

  return (
    <Card loading={error.loading}>

      {
        !error?.data?.[0]?.solved ? (
          <div className="flex justify-end mb-5">
            <Button
              text='Mark as solved'
              color='red'
              action={solveError}
            />
          </div>
        ) : null
      }


      {
        Object.keys(form || {}).map(key => (
          key === 'body' ?
            <div key={key}>
              <Label text='Body' />
              <pre className='overflow-x-auto mb-4'>
                {JSON.stringify(JSON.parse(form[key].value), null, 2)}
              </pre>
            </div>

            : form[key].value && <TextInput
              key={key}
              label={form[key].label}
              value={form[key].value}
            />
        ))
      }
    </Card>
  );
}