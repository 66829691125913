import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import { Animate, Button, Card, Checkbox, useView } from 'components/lib'
import axios from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'

function SupportedTypes() {
    const view = useView()

    const [types, setTypes] = useState([])
    const [additionalTypes, setAdditionalTypes] = useState([])
    const [enableAdditionalTypes, setEnableAdditionalTypes] = useState(false)

    const { data, isLoading, refetch, isSuccess } = useQuery({
        queryKey: ['supported_types'],
        queryFn: () => axios.get("/api/settings/supported_types").then(res => res.data?.data)
    })

    useEffect(() => {
        if (isSuccess) {
            setTypes(data.supported_types)
            setAdditionalTypes(data.additional_supported_types)
            setEnableAdditionalTypes(data.additional_supported_types_enabled)
        }
    }, [isSuccess, data])


    const saveMutation = useMutation({
        mutationFn: () => axios.put("/api/settings/supported_types", { types, additionalTypes, enableAdditionalTypes })
    })

    const handleSave = async () => {
        try {
            const res = await saveMutation.mutateAsync()
            view.notification.show(res.data?.message, 'success', true)
            refetch()
        } catch (e) {
            view.handleError(e)
        }
    }

    return (
        <>
            <Nav />
            <Animate loading={isLoading}>
                <div className='grid xl:grid-cols-2 gap-6'>
                    <Card title='Places Types' loading={isLoading} >
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {
                                types.sort((a, b) => a.key.localeCompare(b.key)).map(v => (
                                    <Checkbox
                                        key={v.key}
                                        option={v.key}
                                        value={v.key}
                                        checked={v.checked}
                                        onChange={() => {
                                            setTypes(prev => prev.map(p => {
                                                if (p.key === v.key) {
                                                    p.checked = !p.checked
                                                }

                                                return p
                                            }))
                                        }}
                                    />
                                ))
                            }
                        </div>
                    </Card>

                    <Card title='Additional Types'>

                        <div className='mb-12 font-bold'>
                            <Checkbox
                                option='Enabled?'
                                value={enableAdditionalTypes}
                                checked={enableAdditionalTypes}
                                onChange={() => setEnableAdditionalTypes(prev => !prev)}
                            />
                        </div>


                        <div aria-disabled={!enableAdditionalTypes} className="aria-disabled:cursor-not-allowed grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {
                                additionalTypes.sort((a, b) => a.key.localeCompare(b.key)).map(v => (
                                    <Checkbox
                                        key={v.key}
                                        option={v.key}
                                        value={v.key}
                                        checked={v.checked}
                                        disabled={!enableAdditionalTypes} 
                                        onChange={() => {
                                            setAdditionalTypes(prev => prev.map(p => {
                                                if (p.key === v.key) {
                                                    p.checked = !p.checked
                                                }
                                                return p
                                            }))
                                        }}
                                    />
                                ))
                            }
                        </div>
                    </Card>
                </div>

                <Button text='Save' action={handleSave} loading={saveMutation.isLoading} />

            </Animate>
        </>
    )
}

export default SupportedTypes