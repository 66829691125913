import { Label } from 'components/lib';
import Style from './checkbox.tailwind.js';
import { useId } from 'react';

export function Checkbox(props) {
  const id = useId()

  return (
    <div>
      <input
        type='checkbox'
        name={props.name}
        id={id}
        className={Style.input}
        required={props.required}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        value={true}
        onChange={e => {
          props?.callback && props.callback(props.index)
          props?.onChange && props.onChange(props.name, e.target.checked)
        }}
        disabled={props.disabled}
      />
      <Label
        text={props.option}
        required={props.required}
        for={id}
        className={Style.label}
      />
    </div>
  );
}