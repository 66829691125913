import { DateRange, Stat, TitleRow, CustomStat } from 'components/lib'
import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'

function CampaignStats(props) {
    const { campaign } = props
    const [dateBetween, setDateBetween] = useState([
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
    ])

    const { data, isLoading } = useQuery({
        queryKey: ['campaign', 'stats', campaign, dateBetween],
        queryFn: () => axios.post(`/api/campaign/stats`, { campaign, dateBetween })
    })

    const sms = data?.data?.sms || []
    const calls = data?.data?.calls || []
    const links = data?.data?.links || []
    const logs = data?.data?.logs || []
    const plan = data?.data?.plan || {}

    const incomingSMS = sms.filter(s => s.direction === 'incoming')
    const outgoingSMS = sms.filter(s => s.direction === 'outgoing')

    const workingHoursSMS = outgoingSMS.filter(s => s.isWithin)
    const afterHoursSMS = outgoingSMS.filter(s => !s.isWithin)


    const workingHoursCalls = calls.filter(c => c.isWithin)
    const afterHoursCalls = calls.filter(c => !c.isWithin)


    const workingHoursPushes = logs.filter(l => (l.isWithin && l.type === 'gather' && l.message === "1"))
    const afterHoursPushes = logs.filter(l => (!l.isWithin && l.type === 'gather' && l.message === "1"))



    const getSum = (arr) => {
        return _.sum(arr.map(s => parseInt(s.count_as))).toLocaleString()
    }

    // useEffect(() => {
    //     if (props.needReload) {
    //         refetch()
    //     }

    // }, [props.needReload])

    return (
        <TitleRow loading={props.loading} title={props.name && `Campaign Analytics for: ${props.name} - ${props.callforwarding_number}`}>

            <DateRange
                value={dateBetween}
                onChange={(e) => setDateBetween([moment(e[0]).format('YYYY-MM-DD'), moment(e[1]).format('YYYY-MM-DD')])}
            />

            <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4'>
                <Stat
                    loading={isLoading}
                    value={`${plan?.totalSentMessages} / ${plan?.totalMessagesPerMonth}`}
                    label='TOTAL SENT SMS'
                    label2='TOTAL CAMPAIGN SENT SMS'
                    value2={getSum(outgoingSMS)}
                />
                <CustomStat loading={isLoading}>
                    <div className='relative text-center min-h-max'>
                        <div className='text-sm text-slate-400 mb-3'>Working Hours</div>
                        <div className='flex flex-col'>
                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>CALLS:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{workingHoursCalls.length.toLocaleString()}</div>
                            </div>
                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>Press 1:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{workingHoursPushes.length.toLocaleString()}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>SMS:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{getSum(workingHoursSMS.filter(s => s.event_type === 'gather'))}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>VM Requests:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{workingHoursSMS.filter(s => s.event_type === 'VM').length}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>CB Requests:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{workingHoursSMS.filter(s => s.event_type === 'CB').length}</div>
                            </div>
                        </div>
                    </div>
                </CustomStat>
                <CustomStat loading={isLoading}>
                    <div className='relative text-center min-h-max'>
                        <div className='text-sm text-slate-400 mb-3'>After Hours</div>
                        <div className='flex flex-col'>
                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>CALLS:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{afterHoursCalls.length.toLocaleString()}</div>
                            </div>
                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>Press 1:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{afterHoursPushes.length.toLocaleString()}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>SMS:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{getSum(afterHoursSMS.filter(s => s.event_type === 'gather'))}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>VM Requests:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{afterHoursSMS.filter(s => s.event_type === 'VM').length}</div>
                            </div>

                            <div className="grid grid-cols-2 items-center">
                                <span className='font-semibold'>CB Requests:</span>
                                <div className='text-[1.75rem] font-bold text-left -ml-2'>{afterHoursSMS.filter(s => s.event_type === 'CB').length}</div>
                            </div>
                        </div>
                    </div>
                </CustomStat>
                <CustomStat loading={isLoading}>
                    <div className="flex justify-around">
                        <div className='flex flex-col gap-y-1'>
                            <span className='font-semibold pb-3'>Incoming</span>
                            <div className='text-sm'>STOP: {getSum(incomingSMS.filter(s => ['STOP', 'OPT_OUT'].includes(s.event_type)))}</div>
                            <div className='text-sm'>START: {getSum(incomingSMS.filter(s => ['START', 'OPT_IN'].includes(s.event_type)))}</div>
                            <div className='text-sm'>HELP: {getSum(incomingSMS.filter(s => s.event_type === 'HELP'))}</div>
                            <div className='text-sm'>INFO: {getSum(incomingSMS.filter(s => s.event_type === 'INFO'))}</div>
                            <div className='text-sm'>UNKNOWN: {getSum(incomingSMS.filter(s => s.event_type === 'UNKNOWN'))}</div>
                        </div>
                        <div className='border w-[1px]'></div>
                        <div className='flex flex-col gap-y-1'>
                            <span className='font-semibold pb-3'>Outgoing</span>
                            <div className='text-sm'>STOP: {getSum(outgoingSMS.filter(s => ['STOP', 'OPT_OUT'].includes(s.event_type)))}</div>
                            <div className='text-sm'>START: {getSum(outgoingSMS.filter(s => ['START', 'OPT_IN'].includes(s.event_type)))}</div>
                            <div className='text-sm'>HELP: {getSum(outgoingSMS.filter(s => s.event_type === 'HELP'))}</div>
                            <div className='text-sm'>INFO: {getSum(outgoingSMS.filter(s => s.event_type === 'INFO'))}</div>
                            <div className='text-sm'>UNKNOWN: {getSum(outgoingSMS.filter(s => s.event_type === 'UNKNOWN'))}</div>
                            <div className='text-sm'>VM NOTIFY: {getSum(outgoingSMS.filter(s => s.event_type === 'VM'))}</div>
                            <div className='text-sm'>CB NOTIFY: {getSum(outgoingSMS.filter(s => s.event_type === 'CB'))}</div>
                        </div>
                    </div>
                </CustomStat>
                <Stat
                    loading={isLoading}
                    value={_.sum(links.map(l => l.clicks))}
                    label='Links Clicked'
                />
            </div >
        </TitleRow >
    )
}

export default CampaignStats